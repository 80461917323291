import {
  Alert,
  CircularProgress,
  Grid,
  Stack,
  Typography
} from "@mui/material";
import useGetExamPlans from "Api/hooks/useGetExamPlans";
import useGetPackage from "Api/hooks/useGetPackage";
import { useEffect, useState } from "react";
import { Link, useNavigate, useSearchParams } from "react-router-dom";

import axios from "../../utils/axios";

import MainCard from "components/MainCard";
import CartDiscount from "components/payment/CartDiscount";

import { LoadingButton } from "@mui/lab";
import useGetExam from "Api/hooks/useGetExam";
import usePayByCredit from "Api/hooks/usePayByCredit";
import usePersianPayment from "Api/hooks/usePersianPayment";
import useUser from "Api/hooks/useUser";
import CartSumamry from "components/payment/CartSummary";
import PayByCreditForm from "components/payment/PayByCreditForm";
import PaymentFeedback from "components/payment/PaymentFeedback";
import Pricing from "components/pricing/PlanCards";
import { enqueueSnackbar } from "notistack";
import { CheckoutForm } from "pages/stripe/Stripe";
import { HiInformationCircle } from "react-icons/hi2";
import { logEvent } from "utils/analytics";

export const creditword = (c) => (c > 1 ? c + " writings" : c + " writing");
function Payment() {
  const [searchParams, setSearchParams] = useSearchParams();
  const examId = searchParams.has("examId") ? searchParams.get("examId") : null;
  const planId = searchParams.has("planId") ? searchParams.get("planId") : null;
  const [selectedPlan, setSelectedPlan] = useState();
  const { examPlans, isLoading: isExamPlanLoading } = useGetExamPlans(examId);
  const [couponCode, setCouponCode] = useState("");
  const [isCouponLoading, setIsCouponLoading] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [amounts, setAmounts] = useState();
  const { exam, refetchExam } = useGetExam(examId);
  const { user, refetch, isPersian } = useUser();
  const { writingPackage, isLoading: isPackageLoading } = useGetPackage(planId);
  const hasEnoughWritings = user?.user?.credit >= exam?.writing_count;
  const [paymentData, setPaymentData] = useState();
  const { payByCredit, isPaying } = usePayByCredit();
  const { payByIRT, paymentLoading } = usePersianPayment();
  const navigate = useNavigate();
  useEffect(() => {
    if (planId) {
      setSelectedPlan(writingPackage);
      return;
    }
    if (examPlans && examPlans.length == 1) {
      setSelectedPlan(examPlans[0]);
    }
  }, [examPlans, writingPackage]);
  useEffect(() => {
    setCouponCode("");
    calculateAmounts();
  }, [selectedPlan]);
  useEffect(() => {
    if (!selectedPlan) return;
    if (examId && hasEnoughWritings) return;
    if (user?.user?.credit >= exam?.writing_count) return;
    logEvent("begin_checkout", {
      currency: "USD",
      value: selectedPlan?.price,
      items: [
        {
          item_id: selectedPlan?.id,
          item_name: selectedPlan?.description,
          price: selectedPlan?.price,
        },
      ],
    });
  }, [selectedPlan]);
  function calculateAmounts() {
    const plan_id = planId ? planId : selectedPlan?.id;
    if (!selectedPlan) return;
    setIsLoading(true);
    return axios
      .get(
        `/orders/amounts?plan_id=${plan_id}&coupon_code=${couponCode}${
          isPersian ? "&currency=IRT" : ""
        }`
      )
      .then((result) => {
        const amounts = result.data.data;
        setAmounts(amounts);
        if (couponCode) {
          enqueueSnackbar(
            !amounts.coupon_is_valid
              ? "Your coupon code is invalid."
              : "Coupon applied on your order.",
            {
              variant: amounts?.coupon_is_valid ? "success" : "error",
              autoHideDuration: 2000,
            }
          );
        }
      })
      .finally(() => {
        const paymentData = {
          coupon_code: couponCode,
          plan_id,
          ...(examId && { exam_id: examId }),
        };
        setIsLoading(false);
        setPaymentData(paymentData);
      });
  }
  console.log(amounts);
  function handleCoupon() {
    setIsCouponLoading(true);
    calculateAmounts().finally(() => setIsCouponLoading(false));
  }
  function submitPayment() {
    const paymentData = {
      plan_id: planId ? Number(planId) : selectedPlan?.id,
      exam_id: examId ? Number(examId) : null,
      coupon_code: couponCode,
    };
    // axios.post("/orders", paymentData).then((res) => {
    //   console.log(res.data);
    //   const paymentResult = res?.data?.data;
    //   const { total_amount, status, payment_url } = paymentResult;
    //   if (total_amount === 0) {
    //     if (examId) {
    //       navigate(`/processing/${examId}`);
    //     } else {
    //       navigate("/transaction-complete");
    //     }
    //     return;
    //   }
    //   if (status == "processing") {
    //     window.location.href = payment_url;
    //     return;
    //   }
    // });
    payByIRT(paymentData, {
      onSuccess: (data) => {
        const { payable_amount, status, payment_url } = data;
        if (examId) {
          if (payable_amount == "0.00" && status == "done") {
            navigate(`/processing/${examId}`);
            return;
          }
          if (payable_amount !== "0.00" && status == "processing") {
            window.location.href = payment_url;
            return;
          }
        } else {
          if (payable_amount == 0) {
            refetch();
            navigate("/transaction-complete");
          } else {
            window.location.href = payment_url;
          }
        }
      },
    });
  }
  if (isExamPlanLoading || isPackageLoading || isPaying)
    return <CircularProgress sx={{ margin: "auto" }} />;
  if (hasEnoughWritings && examId)
    return <PayByCreditForm payByCredit={() => payByCredit(examId)} />;
  return (
    <Stack>
      <Typography variant="h2" fontWeight={700} sx={{ marginBottom: "0.5rem" }}>
        Payment
      </Typography>
      <div style={{ marginBottom: "1.5rem" }}>
        <PaymentFeedback />
      </div>
      {examPlans && examPlans?.length > 1 && !selectedPlan && (
        <Stack sx={{ marginBottom: "2rem 0" }}>
          <Pricing allPlans={examPlans} setSelectedPlan={setSelectedPlan} />
        </Stack>
      )}
      {selectedPlan && (
        <Grid container spacing={3}>
          <Grid item xs={12} md={8}>
            <CartSumamry
              examId={examId}
              exam={exam}
              setSelectedPlan={setSelectedPlan}
              selectedPlan={selectedPlan}
              isLoading={isLoading}
              hasEnoughWritings={hasEnoughWritings}
              plans={examPlans}
              amounts={amounts}
            />
            {!isPersian && selectedPlan && paymentData ? (
              <Grid item marginTop={2}>
                <MainCard>
                  <CheckoutForm
                    paymentData={paymentData}
                    key={paymentData.coupon_code}
                  />
                </MainCard>
              </Grid>
            ) : null}
          </Grid>
          <Grid item xs={12} md={4}>
            <Stack spacing={3}>
              {amounts?.payable_amount !== "0" && (
                <MainCard>
                  <CartDiscount
                    setCouponCode={setCouponCode}
                    handleCoupon={handleCoupon}
                    loading={isCouponLoading}
                    couponCode={couponCode}
                  />
                </MainCard>
              )}
              <Grid item>
                <Alert color="success" icon={<HiInformationCircle />}>
                  * By clicking "Pay" I authorize this payment and agree with
                  the{" "}
                  <Link
                    style={{ textDecoration: "none", color: "green" }}
                    to="https://www.writingchex.com/terms"
                    target="_blank"
                  >
                    {" "}
                    terms of service and payment and refund policies.
                  </Link>
                </Alert>
                {isPersian && (
                  <LoadingButton
                    onClick={submitPayment}
                    fullWidth
                    variant="contained"
                    sx={{ mt: "1rem" }}
                    loading={paymentLoading}
                  >
                    Pay
                  </LoadingButton>
                )}
              </Grid>
            </Stack>
          </Grid>
        </Grid>
      )}
    </Stack>
  );
}

export default Payment;
