import {
  Button,
  CardContent,
  CircularProgress,
  Fade,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Modal,
  Stack,
  TextField,
  Typography,
  useMediaQuery,
} from "@mui/material";
import Avatar from "components/@extended/Avatar";
import MainCard from "components/MainCard";
import React, { useEffect, useState } from "react";
import { HiPencil } from "react-icons/hi2";
import { countwords } from "utils/contwords";
import "../../pages/main.css";
import { IoCheckmarkSharp } from "react-icons/io5";
import StopWatch from "components/spellingPractice/StopWatch";
import { useNavigate } from "react-router";
import { enqueueSnackbar } from "notistack";
import { GrPowerReset } from "react-icons/gr";
import useGetSpellingTask from "Api/hooks/useGetSpellingTask";
import zIndex from "@mui/material/styles/zIndex";
function Spelling() {
  const [answer, setAnswer] = useState("");
  const textarea = document.getElementById("spelling-answer");
  const contentDiv = document.getElementById("spelling-task");
  const [modalOpen, setModalOpen] = useState(false);
  const [StopWatchTime, setStopWatchTime] = useState(0);
  const [isRunning, setIsRunning] = useState(false);
  const { spellingTask, isLoading } = useGetSpellingTask();
  const [isFirstInput,setIsFirstInput] = useState(true)
  const isMobile = useMediaQuery("(max-width:750px)");
  const navigate = useNavigate();
  textarea?.addEventListener("scroll", syncScroll);
  textarea?.addEventListener("input", syncScroll);
  function syncScroll() {
    contentDiv.scrollTop = textarea.scrollTop + 5;
  }
  function startExam() {
    // setIsRunning(true);
    setModalOpen(false);
  }
  function finishExam(e) {
    e.preventDefault();
    setIsRunning(false);
    const time = StopWatchTime;
    const writingData = {
      task_description: spellingTask?.text,
      task_answer: answer,
      time,
    };
    if (
      countwords(writingData.task_answer) <
      countwords(writingData.task_description) / 2
    ) {
      enqueueSnackbar({
        variant: "error",
        message: "Please type in the full writing",
      });
      return;
    }
    navigate("/spellingResult", { state: writingData });
  }
  function restartExam() {
    setAnswer("");
    setStopWatchTime(0);
    setIsRunning(false);
    setIsFirstInput(true)
  }
  const handleFirstInput = (event) => {
    if (isFirstInput) {
      setIsRunning(true)
      setIsFirstInput(false);
    }
  };
  if (isLoading) return <CircularProgress sx={{ margin: "auto" }} />;
  return (
    <>
      {/* {modalOpen ? (
        <Fade
          in={modalOpen}
          sx={{ border: "none", outline: "none", zIndex: 12345678324234 }}
        >
          <MainCard
            title="Spelling Practice"
            modal
            darkTitle
            content={false}
            sx={{ outline: "none", border: "none", zIndex: 123456789 }}
          >
            <CardContent>
              <Typography variant="h5" fontWeight="500">
                Improve your spelling proficiency with our specialized practice
                tool. Designed to target the most commonly misspelled words on
                the IELTS.
              </Typography>
              <Stack
                marginTop="2rem"
                direction="row"
                justifyContent="space-between"
              >
                <Button
                  variant="contained"
                  onClick={() => {
                    startExam();
                  }}
                  size="large"
                >
                  Start
                </Button>
                <Button
                  variant="outlined"
                  color="error"
                  size="large"
                  onClick={() => navigate("/")}
                >
                  quit
                </Button>
              </Stack>
            </CardContent>
          </MainCard>
        </Fade>
      ) : null} */}
      <Stack sx={modalOpen ? { filter: "blur(20px)" } : ""}>
        <MainCard>
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            spacing={5}
          >
            <List disablePadding>
              <ListItem sx={{ p: 0 }}>
                <ListItemAvatar>
                  <Avatar color="info" variant="rounded">
                    <HiPencil />
                  </Avatar>
                </ListItemAvatar>
                <ListItemText
                  sx={{ my: 0 }}
                  primary={
                    <Typography variant="subtitle1">
                      {spellingTask.text ? countwords(spellingTask.text) : 0}{" "}
                      words
                    </Typography>
                  }
                  secondary={
                    <Typography variant="subtitle2">
                      Spelling Practice
                    </Typography>
                  }
                />
              </ListItem>
            </List>
            <Stack direction="row" alignItems="center" spacing={2}>
              <IconButton color="info" onClick={restartExam}>
                <GrPowerReset />
              </IconButton>
              <StopWatch
                isRunning={isRunning}
                time={StopWatchTime}
                setTime={setStopWatchTime}
              />
            </Stack>
          </Stack>
        </MainCard>
        <Grid container marginTop={1} spacing={1} justifyContent="center">
          <Grid item xs={12} sm={6}>
            <MainCard title="Task description ">
              <div
                className="scrollable"
                style={{
                  height: isMobile ? "100px" : "200px",
                  fontSize: "16px",
                }}
                id="spelling-task"
                dangerouslySetInnerHTML={{ __html: spellingTask.text }}
              ></div>
            </MainCard>
          </Grid>
          <Grid item xs={12} sm={6}>
            <form onSubmit={(e) => finishExam(e)} spellCheck="false" autoCorrect="false" autoComplete="false">
              <MainCard
                title={
                  <Stack
                    direction="row"
                    alignItems="center"
                    justifyContent="space-between"
                  >
                    <Typography variant="subtitle1">Task Answer</Typography>
                    <Typography sx={{ display: "flex" }}>
                      <Typography
                        color={countwords(answer) > 20 ? "green" : "orange"}
                      >
                        {countwords(answer)}
                      </Typography>
                      &nbsp;words
                    </Typography>
                  </Stack>
                }
              >
                <TextField
                data-gramm="false"
                  sx={{ height: isMobile ? "120px" : "200px" }}
                  className="scrollable"
                  id="spelling-answer"
                  multiline
                  rows={isMobile ? 4 : 8}
                  name="answer"
                  fullWidth
                  value={answer}
                  onChange={(e) => setAnswer(e.target.value)}
                  placeholder="write your answer here..."
                  onInput={handleFirstInput}
                />
              </MainCard>
              <MainCard sx={{ marginTop: "0.6rem" }}>
                <Stack
                  alignItems="center"
                  direction="row"
                  justifyContent="space-between"
                  spacing={1}
                >
                                    <Button
                    variant="outlined"
                    color="error"
                    onClick={() => navigate("/")}
                  >
                    Quit
                  </Button>
                  <Button
                    variant="contained"
                    endIcon={<IoCheckmarkSharp />}
                    type="submit"
                  >
                    Check
                  </Button>
                </Stack>
              </MainCard>
            </form>
          </Grid>
        </Grid>
      </Stack>
    </>
  );
}

export default Spelling;
