import axios from "../../utils/axios";
export function getUserApi() {
  return axios.get("/context").then(({ data }) => data);
}
export function dashboardApi(taskType, examType) {
  return axios
    .get(`dashboard?task_type=${taskType}&exam_type=${examType}`)
    .then(({ data }) => data.data);
}
export function contactApi(data) {
  return axios.post("contact", data).then(({ data }) => data.data);
}
export function getCountryApi() {
  return axios.get("/country").then(({ data }) => data.country);
}
export function getProfieApi() {
  return axios.get("/profile").then(({ data }) => data.data);
}
export function setProfielApi(data) {
  return axios.post("/profile", data).then(({ data }) => data.data);
}
