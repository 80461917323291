import React, { useState } from "react";
import MainCard from "./MainCard";
import { Button, Stack, Typography } from "@mui/material";
import email from "../assets/images/email.png";
import useUser from "Api/hooks/useUser";
import OTPInput from "./OTPInput";
import { Modal } from "@mui/base";
import { LoadingButton } from "@mui/lab";
import OTPCoundown from "./OTPCountdown";
import { Link } from "react-router-dom";
import OtpInput from "react-otp-input";

function ConfirmEmailBox({
  verify,
  resend,
  isVerifying,
  isResending,
  code,
  setCode,
}) {
  const user = useUser();
  return (
    <MainCard
      sx={{
        width: { xs: "360px", sm: "450px", md: "650px" },
        border: "none",
      }}
    >
      <Stack alignItems="center">
        <img src={email} style={{ width: "150px", height: "150px" }} />
        <Typography variant="h4">Confirm your email address</Typography>
        <Typography
          variant="subtitle1"
          sx={{
            marginTop: "1rem",
            textAlign: "center",
            maxWidth: "500px",
          }}
        >
          confirm your email address and recieve a free writing! We sent
          verification code to{" "}
          <span style={{ color: "#22c55e" }}>{user?.user?.user?.email}</span>
          &nbsp; Please check your inbox and enter the code below.
        </Typography>
        <Stack sx={{ marginTop: "1.5rem" }} gap={2}>
          <Typography sx={{ textAlign: "center" }}>
            Enter your 6-digits code
          </Typography>
          {/* <OTPInput code={code} setCode={setCode} /> */}
          <OtpInput
            value={code}
            onChange={setCode}
            numInputs={6}
            renderSeparator={<span>-</span>}
            containerStyle="otp-container"
            inputStyle={{
              width: "2.5rem",
              padding: "0.6rem 0.2rem",
              borderRadius: "10px",
              border:'0.8px solid #cbd5e1',
              boxShadow: 'rgba(149, 157, 165, 0.2) 0px 8px 24px;'
            }}
            renderInput={(props) => <input {...props} inputMode="numeric" type='number' className="otp-input"/>}
          />
          <LoadingButton
            variant="contained"
            loading={isVerifying}
            onClick={() => verify(code)}
            sx={{textTransform:'none'}}
          >
            Verify and get a free writing
          </LoadingButton>
          <Button
          sx={{textTransform:'none'}}
            variant="contained"
            color="secondary"
            LinkComponent={Link}
            to="/"
            autoCapitalize={false}
          >
            Skip for now
          </Button>
        </Stack>
        <Stack
          direction="row"
          alignItems="center"
          sx={{ marginTop: "1rem" }}
          gap={1}
        >
          <Typography>Didn&apos;t receive the code?</Typography>
          {/* <Button variant="text">Resend Code</Button> */}
          {/* <LoadingButton loading={isResending} onClick={resend}>Resend Code</LoadingButton> */}
          <OTPCoundown resend={resend} />
        </Stack>
      </Stack>
    </MainCard>
  );
}

export default ConfirmEmailBox;
