import { useState } from "react";

// material-ui
import {
  Alert,
  Button,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  TextField,
} from "@mui/material";

// third-party

// project-imports
import MainCard from "components/MainCard";

// assets
import { InfoCircle } from "iconsax-react";
import { ScoreGoal } from "components/ScoreGoal";

const UserExamGoals = () => {
  const [examType, setExamType] = useState("");
  const [goal, setGoal] = useState(5);
  const [examGoal, setExamGoal] = useState("");
  function handleGoalChange(type) {
    switch (type) {
      case "minus":
        if (goal === 5) return;
        setGoal(goal - 0.5);
        break;
      case "plus":
        if (goal === 9) return;
        setGoal(goal + 0.5);
        break;
      default:
        return;
    }
  }
  return (
    <MainCard title="Exam Goals">
      <Stack sx={{ marginBottom: "1rem" }}>
        <Alert color="info" icon={<InfoCircle />}>
          Here you can add information about the exam that you are about to
          take, your targetted score and your exam goals, WritingChex will use
          this information to assist to you in the best way possible.
        </Alert>
      </Stack>
      <Grid container spacing={3}>
        <Grid item xs={12} sm={6}>
          <Stack>
            <InputLabel htmlFor="exam-type" sx={{ marginBottom: "0.5rem" }}>
              Exam type
            </InputLabel>
            <Select
              fullWidth
              value={examType}
              name="exam-type"
              placeholder="set your exam type"
              onChange={(e) => setExamType(e.target.value)}
            >
              <MenuItem value="academic">IELTS Academic</MenuItem>
              <MenuItem value="general">IELTS General</MenuItem>
            </Select>
          </Stack>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Stack>
            <InputLabel htmlFor="exam-goal" sx={{ marginBottom: "0.5rem" }}>
              Exam Goal
            </InputLabel>
            <TextField
              name="exam-goal"
              id="exam-goal"
              value={examGoal}
              placeholder="What do you want to achieve with this exam?"
              onChange={(e) => setExamGoal(e.target.value)}
            />
          </Stack>
        </Grid>
        <Grid item xs={12}>
          <ScoreGoal handleGoalChange={handleGoalChange} goal={goal} />
        </Grid>
        <Grid item xs={12}>
          <Stack
            direction="row"
            justifyContent="flex-end"
            alignItems="center"
            spacing={2}
          >
            <Button variant="outlined" color="secondary">
              Cancel
            </Button>
            <Button variant="contained" disabled={!examType || !examGoal}>Save</Button>
          </Stack>
        </Grid>
      </Grid>
    </MainCard>
  );
};

export default UserExamGoals;
