import {
  Button,
  Grid,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Stack,
  Typography,
} from "@mui/material";
import React from "react";
import MainCard from "../MainCard";
import { ArchiveBook, ArrowLeft, Chart1, Chart21, Global } from "iconsax-react";
import Avatar from "../@extended/Avatar";
import { useNavigate, useSearchParams } from "react-router-dom";
import { IoLibraryOutline } from "react-icons/io5";
import { IoMdGlobe } from "react-icons/io";
import EcommerceDataCard from "components/cards/statistics/EcommerceDataCard";

export const examOptions = [
  {
    name: "exam_type",
    logo: <IoLibraryOutline />,
    title: "IELTS Academic",
    color: "secondary",
    value: "academic",
    description:
      "The IELTS Academic Writing test includes two tasks. Task 1 requires you to describe visual data (charts, graphs, tables, or diagrams) in at least 150 words. Task 2 involves writing a 250-word essay on a complex topic. Focus on clear structure, formal tone, and precise vocabulary to analyze data and construct well-supported arguments.",
  },
  {
    name: "exam_type",
    logo: <IoMdGlobe />,
    title: "IELTS General",
    color: "secondary",
    value: "general",
    description:
      "The IELTS General Writing test also includes two tasks. Task 1 asks you to write a letter (formal, semi-formal, or informal) in at least 150 words. Task 2 requires a 250-word essay on a general topic. Emphasize clear structure, appropriate tone, and practical language skills to effectively communicate your message and support your ideas.",
  },
];
export const taskOptions = [
  {
    name: "task_type",
    logo: <Chart21 size={32} />,
    academic_title: "IELTS Academic Task 1",
    general_title: "IELTS General Task 1",
    color: "secondary",
    value: 1,
    academic_description:
      "In IELTS Academic Writing Task 1, you have 20 minutes to write at least 150 words describing visual information, such as charts, graphs, tables, or diagrams.",
    general_description:
      "In IELTS General Writing Task 1, you have 20 minutes to write a letter of at least 150 words in response to a given situation. The letter should be formal, semi-formal, or informal, addressing the purpose clearly with appropriate details and a suitable tone.",
  },
  {
    name: "task_type",
    logo: <Chart1 size={32} />,
    academic_title: "IELTS Academic Task 2",
    general_title: "IELTS General Task 2",
    color: "secondary",
    value: 2,
    academic_description:
      "In IELTS Academic Writing Task 2, you have 40 minutes to write an essay of at least 250 words responding to a given prompt, which may include opinion essays, discussion essays, problem-solution essays, or advantages-disadvantages essays.",
    general_description:
      "In IELTS General Writing Task 2, you have 40 minutes to write an essay of at least 250 words responding to a given prompt, which may include opinion essays, discussion essays, problem-solution essays, or advantages-disadvantages essays",
  },
];
function SimulatorOptions({ title1, title2 = "" }) {
  const [searchParams, setSearchParams] = useSearchParams();
  const isStep1 = searchParams.has("exam_type");
  const exam_type = searchParams.get("exam_type");
  const options = isStep1 ? taskOptions : examOptions;
  const title = isStep1 ? title2 : title1;
  const type = isStep1 ? "task_type" : "exam_type";
  const navigate = useNavigate();
  return (
    <Stack spacing={5} alignItems="center">
      <Typography variant="h2" fontWeight={700}>
        {title}
      </Typography>
      <Grid container spacing={1} maxWidth={800}>
        {options.map((option) => (
          <Grid
            item
            xs={12}
            sm={6}
            // sx={{
            //   display: "flex",
            //   alignItems: "center",
            //   justifyContent: "center",
            //   margin:"0 1rem"
            // }}
          >
            <EcommerceDataCard
              onClick={() =>
                setSearchParams((prev) => [
                  ...prev.entries(),
                  [type, option.value],
                ])
              }
              title={
                !isStep1
                  ? option.title
                  : exam_type == "academic"
                  ? option.academic_title
                  : exam_type == "general"
                  ? option.general_title
                  : ""
              }
              color="success"
              iconPrimary={option.logo}
              to="scoring"
            >
              <Typography>
                {!isStep1
                  ? option.description
                  : exam_type == "academic"
                  ? option.academic_description
                  : exam_type == "general"
                  ? option.general_description
                  : ""}
              </Typography>
            </EcommerceDataCard>
          </Grid>
        ))}
      </Grid>
      <Button variant="contained" color="error" onClick={() => navigate(-1)}>
        <ArrowLeft />
      </Button>
    </Stack>
  );
}

export default SimulatorOptions;
