import {
  Alert,
  CircularProgress,
  Divider,
  Grid,
  Stack,
  TableCell,
  Typography,
} from "@mui/material";
import useUser from "Api/hooks/useUser";
import MainCard from "components/MainCard";
import { InfoCircle } from "iconsax-react";
import { creditword } from "pages/payment/Payment";
import { cword } from "utils/cword";
import { toNumWithComma } from "utils/toNumWithComma";

export default function CartSumamry({
  amounts,
  hasEnoughWritings,
  isLoading,
  exam,
  plans,
  selectedPlan,
  setSelectedPlan,
  examId,
}) {
  const { user, isPersian } = useUser();
  console.log(user);
  return (
    <Stack spacing={2}>
      <MainCard content={false}>
        <Grid container>
          <Grid item xs={12} sx={{ py: 2.5, pl: 2.5 }}>
            <Stack
              direction="row"
              alignItems="center"
              justifyContent="space-between"
            >
              <Typography variant="subtitle1">Cart</Typography>
              {plans?.length > 1 && (
                <Typography
                  color="green"
                  onClick={() => setSelectedPlan()}
                  style={{ marginRight: "1rem" }}
                >
                  Change Plan?
                </Typography>
              )}
            </Stack>
          </Grid>
          <Grid item xs={12}>
            <Divider />
          </Grid>
          {isLoading && <CircularProgress sx={{ margin: "2rem" }} />}
          {!isLoading && (
            <Grid item xs={12}>
              {!hasEnoughWritings && exam && examId && (
                <Grid container>
                  <Grid item xs={12}>
                    <Alert
                      sx={{ borderRadius: "0" }}
                      color="info"
                      icon={<InfoCircle />}
                    >
                      <Typography>
                        {exam?.service_title} has&nbsp;
                        {creditword(exam?.writing_count)},<br />
                        You need {cword(exam.writing_count - user.user.credit)}
                      </Typography>
                    </Alert>
                  </Grid>
                </Grid>
              )}
              <Grid
                container
                alignItems="center"
                justifyContent="space-between"
                sx={{ borderBottom: "1px solid #e2e8f0" }}
              >
                <Grid item xs={6} s={4} md={4} lg={4}>
                  <TableCell component="th" scope="row" sx={{ border: "none" }}>
                    <Grid container alignItems="center" spacing={2}>
                      <Grid item>
                        <Stack spacing={0}>
                          <Typography
                            variant="subtitle1"
                            color="textPrimary"
                            sx={{ textDecoration: "none" }}
                          >
                            {selectedPlan?.description}
                          </Typography>
                          <Typography color="textSecondary">
                            {creditword(selectedPlan?.credit)}
                          </Typography>
                        </Stack>
                      </Grid>
                    </Grid>
                  </TableCell>
                </Grid>
                <Grid
                  item
                  xs={6}
                  s={4}
                  md={4}
                  lg={4}
                  sx={{
                    display: "flex",
                    justifyContent: "flex-end",
                  }}
                >
                  <TableCell align="right" sx={{ border: "none" }}>
                    <Stack>
                      {!hasEnoughWritings && (
                        <Typography variant="subtitle1">
                          {isPersian ? "" : "$"}
                          {isPersian ? new Number(amounts?.total_amount)?.toString()?.replace(/\B(?=(\d{3})+(?!\d))/g, ",") : toNumWithComma(amounts?.total_amount)}{isPersian ? "T" : ""}&nbsp;
                        </Typography>
                      )}
                      {examId && hasEnoughWritings && (
                        <Typography variant="subtitle1">
                          Enough Balance
                        </Typography>
                      )}
                    </Stack>
                  </TableCell>
                </Grid>
              </Grid>
              {amounts !== null && amounts?.coupon_is_valid ? (
                <Grid
                  container
                  alignItems="center"
                  justifyContent="space-between"
                  sx={{ borderBottom: "1px solid #e2e8f0" }}
                >
                  <Grid item>
                    <TableCell
                      component="th"
                      scope="row"
                      sx={{ border: "none" }}
                    >
                      <Grid container alignItems="center" spacing={2}>
                        <Grid item>
                          <Stack spacing={0}>
                            <Typography
                              variant="subtitle1"
                              color="textPrimary"
                              sx={{ textDecoration: "none" }}
                            >
                              Discount
                            </Typography>
                          </Stack>
                        </Grid>
                      </Grid>
                    </TableCell>
                  </Grid>
                  <TableCell align="right" sx={{ border: "none" }}>
                    <Stack alignItems="end">
                      <Typography variant="subtitle1">
                    -{isPersian ? "" : "$"}
                      {isPersian ? new Number(amounts?.discount_amount)?.toString()?.replace(/\B(?=(\d{3})+(?!\d))/g, ",") : toNumWithComma(amounts?.discount_amount)}{isPersian ? "T" : ""}&nbsp;
                      </Typography>
                    </Stack>
                  </TableCell>
                </Grid>
              ) : (
                ""
              )}
              <Grid
                container
                alignItems="center"
                justifyContent="space-between"
              >
                <Grid item>
                  <TableCell component="th" scope="row" sx={{ border: "none" }}>
                    <Grid container alignItems="center" spacing={2}>
                      <Grid item>
                        <Stack spacing={0}>
                          <Typography
                            variant="subtitle1"
                            color="textPrimary"
                            sx={{ textDecoration: "none" }}
                          >
                            Total
                          </Typography>
                        </Stack>
                      </Grid>
                    </Grid>
                  </TableCell>
                </Grid>
                <TableCell align="right" sx={{ border: "none" }}>
                  <Stack alignItems="end">
                    <Typography variant="subtitle1">
                    {isPersian ? "" : "$"}
                    {isPersian ? new Number(amounts?.payable_amount)?.toString()?.replace(/\B(?=(\d{3})+(?!\d))/g, ",") : toNumWithComma(amounts?.payable_amount)}{isPersian ? "T" : ""}&nbsp;
                    </Typography>
                  </Stack>
                </TableCell>
              </Grid>
            </Grid>
          )}
        </Grid>
      </MainCard>
    </Stack>
  );
}
