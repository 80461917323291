import { useQuery } from "@tanstack/react-query";
import { getExamPlansApi } from "Api/providers/examPlansProvider";

export default function useGetExamPlans(examId) {
  const {
    data: examPlans,
    isLoading,
    refetch,
  } = useQuery({
    queryKey: ["exam-plans", examId],
    queryFn: () => getExamPlansApi(examId),
    enabled: !!examId,
  });
  return { examPlans, isLoading, refetch };
}
