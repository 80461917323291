import { useQuery } from "@tanstack/react-query";
import { getUserApi } from "Api/providers/userProviders";
import useGetCountry from "./useGetCountry";

export default function useUser() {
  const {
    data: user,
    isLoading,
    refetch,
  } = useQuery({
    queryFn: getUserApi,
    queryKey: ["user"],
  });
  const {country} = useGetCountry()
  const isPersian = user?.user?.is_ir ||country == "IR" 
  return { user, isLoading, refetch, isPersian };
}
