import React from "react";
import { ListItemText, Stack, Typography } from "@mui/material";
import MainCard from "components/MainCard";
import { RiArrowLeftSLine, RiArrowRightSLine } from "react-icons/ri";
import { useSwipeable } from "react-swipeable";
import { styled } from "@mui/material/styles";

const AnimatedTypography = styled(Typography)(({ theme }) => ({
  transition: theme.transitions.create(["color", "transform"], {
    duration: theme.transitions.duration.standard,
  }),
}));

const DraggableArea = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  gap: "1.5rem",
  cursor: "grab",
  "&:active": {
    cursor: "grabbing",
  },
  userSelect: "none",
}));

export function ScoreGoal({ handleGoalChange, goal }) {
  const handlers = useSwipeable({
    onSwipedLeft: () => goal < 9 && handleGoalChange("plus"),
    onSwipedRight: () => goal > 5 && handleGoalChange("minus"),
    trackMouse: true,
  });

  return (
    <MainCard>
      <Stack alignItems="center" gap={4}>
        <ListItemText
          primary={
            <Typography variant="h4" sx={{ marginBottom: "0.5rem" }}>
              Set your targetted IELTS writing band score
            </Typography>
          }
          secondary={
            <Typography variant="subtitle1" sx={{ opacity: "55%" }}>
              Select the IELTS writing band score you would like to achieve
            </Typography>
          }
        />
        <Stack direction="row" alignItems="center" gap={1}>
          {goal != 5 && (
            <RiArrowLeftSLine
              fontSize={30}
              className="goal-action"
              style={{
                opacity: "58%",
                cursor: "pointer",
                transition: "opacity 0.3s",
              }}
              onClick={() => handleGoalChange("minus")}
            />
          )}
          <DraggableArea {...handlers}>
            <AnimatedTypography variant="h4" sx={{ opacity: "55%" }}>
              {goal === 5 ? "-" : goal - 0.5}
            </AnimatedTypography>
            <AnimatedTypography
              color="#81f4a6"
              variant="h1"
              sx={{ transform: `scale(${goal % 1 === 0 ? 1.2 : 1})` }}
            >
              {goal}
            </AnimatedTypography>
            <AnimatedTypography variant="h4" sx={{ opacity: "55%" }}>
              {goal === 9 ? "-" : goal + 0.5}
            </AnimatedTypography>
          </DraggableArea>
          {goal != 9 && (
            <RiArrowRightSLine
              fontSize={30}
              className="goal-action"
              style={{
                opacity: "58%",
                cursor: "pointer",
                transition: "opacity 0.3s",
              }}
              onClick={() => handleGoalChange("plus")}
            />
          )}
        </Stack>
      </Stack>
    </MainCard>
  );
}
