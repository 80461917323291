import useImprovments from "Api/hooks/useImprovments";
import { useState, createContext, useEffect, useContext } from "react";

const AppContext = createContext();
const AppContextProvider = ({ children }) => {
  const [taskCount, setTaskCount] = useState(0);
  // const { improvments } = useImprovments();
  // useEffect(() => {
  //   setTaskCount(improvments?.filter((task) => task?.done == false).length)
  // }, [improvments]);
  function refreshApp() {
    setTaskCount((prev) => prev - 1);
  }
  return (
    <AppContext.Provider value={{ taskCount, refreshApp }}>
      {children}
    </AppContext.Provider>
  );
};
export function useApp() {
  const app = useContext(AppContext);
  return app
}
export default AppContextProvider;
