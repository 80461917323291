import { useMutation } from "@tanstack/react-query";
import { postTaskApi } from "Api/providers/taskProvider";
import { useNavigate } from "react-router";
import { logEvent } from "utils/analytics";
export function usePostWriting(type) {
  const navigate = useNavigate();
  const {
    mutateAsync: postWriting,
    isPending: isPosting,
    error,
  } = useMutation({
    mutationFn: postTaskApi,
    onSuccess: (data) => {
      logEvent("writing_submitted", {
        service_title: data?.service_title,
      });
      if (type == "final-submission") {
        navigate("/payment?examId=" + data.id);
      } else {
        navigate("/drafts");
      }
    },
  });
  return { postWriting, isPosting };
}
