import PropTypes from "prop-types";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";

// material-ui
import { useTheme } from "@mui/material/styles";
import {
  Box,
  Divider,
  FormLabel,
  Grid,
  TextField,
  Menu,
  MenuItem,
  Stack,
  Typography,
  Chip,
} from "@mui/material";

// project-imports
import MainCard from "components/MainCard";
import Avatar from "components/@extended/Avatar";
import ProfileTab from "./ProfileTab";
import { facebookColor, linkedInColor } from "config";
import { ThemeMode } from "config";

// assets
import { Apple, Camera, Facebook, Google, More } from "iconsax-react";
import IconButton from "components/@extended/IconButton";
import { useApp } from "contexts/AppContext";
import useUser from "Api/hooks/useUser";
// import SubscriptionBadge from "components/SubscriptionBadge";

const avatarImage = require.context("assets/images/users", true);

// ==============================|| USER PROFILE - TABS ||============================== //

const ProfileTabs = ({ focusInput }) => {
  const theme = useTheme();
  const [selectedImage, setSelectedImage] = useState(undefined);
  const [avatar, setAvatar] = useState(avatarImage(`./avatar1.png`));
  const app = useApp();
  const {user} = useUser()
  useEffect(() => {
    if (selectedImage) {
      setAvatar(URL.createObjectURL(selectedImage));
    }
  }, [selectedImage]);

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event?.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
console.log(user?.user)
  return (
    <MainCard>
      <Grid container spacing={6}>
        <Grid item xs={12}>
          <Stack direction="row" justifyContent="flex-end">
            <IconButton
              variant="light"
              color="secondary"
              id="basic-button"
              aria-controls={open ? "basic-menu" : undefined}
              aria-haspopup="true"
              aria-expanded={open ? "true" : undefined}
              onClick={handleClick}
            >
              <More />
            </IconButton>
            <Menu
              id="basic-menu"
              anchorEl={anchorEl}
              open={open}
              onClose={handleClose}
              MenuListProps={{
                "aria-labelledby": "basic-button",
              }}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "right",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
            >
              <MenuItem
                component={Link}
                to="/user/personal"
                onClick={() => {
                  handleClose();
                  setTimeout(() => {
                    focusInput();
                  });
                }}
              >
                Edit
              </MenuItem>
            </Menu>
          </Stack>
          <Stack spacing={2.5} alignItems="center">
            <FormLabel
              htmlFor="change-avtar"
              sx={{
                position: "relative",
                borderRadius: "50%",
                overflow: "hidden",
                "&:hover .MuiBox-root": { opacity: 1 },
                cursor: "pointer",
              }}
            >
              <Avatar
                alt="Avatar 1"
                src={avatar}
                sx={{ width: 124, height: 124, border: "1px dashed" }}
              />
              {/* <Box
                sx={{
                  position: "absolute",
                  top: 0,
                  left: 0,
                  backgroundColor:
                    theme.palette.mode === ThemeMode.DARK
                      ? "rgba(255, 255, 255, .75)"
                      : "rgba(0,0,0,.65)",
                  width: "100%",
                  height: "100%",
                  opacity: 0,
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <Stack spacing={0.5} alignItems="center">
                  <Camera
                    style={{
                      color: theme.palette.secondary.lighter,
                      fontSize: "2rem",
                    }}
                  />
                  <Typography sx={{ color: "secondary.lighter" }}>
                    Upload
                  </Typography>
                </Stack>
              </Box> */}
            </FormLabel>
            {/* <TextField
              type="file"
              id="change-avtar"
              placeholder="Outlined"
              variant="outlined"
              sx={{ display: "none" }}
              onChange={(e) => setSelectedImage(e.target.files?.[0])}
            /> */}
            <Stack spacing={0.5} alignItems="center">
              <Typography variant="h5">{user?.user?.name}</Typography>
              {/* <Stack direction="row" gap={2}>
                <SubscriptionBadge />
                <Chip
                  label="23 days left"
                  size="small"
                  color="info"
                  variant="filled"
                />
              </Stack> */}
            </Stack>
          </Stack>
        </Grid>
        <Grid item sm={3} sx={{ display: { sm: "block", md: "none" } }} />
        <Grid item xs={12}>
          <ProfileTab />
        </Grid>
      </Grid>
    </MainCard>
  );
};

ProfileTabs.propTypes = {
  focusInput: PropTypes.func,
};

export default ProfileTabs;
