import { useQuery } from "@tanstack/react-query";
import { dashboardApi } from "Api/providers/userProviders";

export default function useDashboard(taskType, examType) {
  const {
    data: dashboard,
    isLoading,
    refetch,
    isRefetching,
  } = useQuery({
    queryKey: ["dashboard"],
    queryFn: ()=>dashboardApi(taskType,examType),
  });
  return { dashboard, refetch, isLoading, refetch, isRefetching };
}
