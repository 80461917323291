import { useQuery } from "@tanstack/react-query";
import { getPackageApi } from "Api/providers/packageProvider";

export default function useGetPackage(planId) {
  const { data: writingPackage, isLoading } = useQuery({
    queryKey: ["writing-package", planId],
    queryFn: () => getPackageApi(planId),
    enabled: !!planId,
  });
  return { writingPackage, isLoading };
}
