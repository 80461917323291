import { lazy } from "react";

// project-imports
import MainLayout from "layout/MainLayout";
import Loadable from "components/Loadable";
import AuthGuard from "utils/route-guard/AuthGuard";
import Pricing from "pages/pricing/Pricing";
import Result from "pages/result/result";
import MyReports from "pages/reports/MyReports";
import Payment from "pages/payment/Payment";
import Report from "pages/report/Report";
import Exam, { ExamStepper } from "pages/exam/Exam";
import Scoring from "pages/exam/Scoring";
import Practice from "pages/exam/Practice";
import Simulator from "pages/exam/Simulator";
import Processing from "pages/processing/Processing";
import Drafts from "pages/drafts/Drafts";
import Dashboard from "pages/dashboard/Dashboard";
import Improvments from "pages/improvments/Improvments";
import Spelling from "pages/spelling/Spelling";
import SpellingResult from "pages/spelling/SpellingResult";
import Quiz from "pages/quiz/Quiz";
import { StripeResultReturn } from "pages/stripe/Stripe";
import Print from "pages/result/Print";
import UserProfile from "sections/apps/profiles/user/user";
import UserTabPersonal from "sections/apps/profiles/user/TabPersonal";
import UserTabPassword from "sections/apps/profiles/user/TabPassword";
import UserTabSettings from "sections/apps/profiles/user/TabSettings";
import UserExamGoals from "sections/apps/profiles/user/UserExamGoals";
import TransactionComplete from "components/transactions/TransactionComplete";
import Unsubscribe from "pages/unsubscribe/Unsubscribe";
// render - sample page
// const MyReports = Loadable(lazy(() => import("pages/reports/MyReports")));
const Orders = Loadable(lazy(() => import("pages/orders/Orders")));
// ==============================|| MAIN ROUTES ||============================== //

const MainRoutes = {
  path: "/",
  element: (
    <AuthGuard>
      <MainLayout />
    </AuthGuard>
  ),
  children: [
    {
      path: "dashboard",
      element: <Dashboard />,
    },
    {
      path:"unsubscribe",
      element:<Unsubscribe/>
    },
    {
      path: "quiz",
      element: <Quiz />,
    },
    {
      path: "spelling",
      element: <Spelling />,
    },
    {
      path: "spellingResult",
      element: <SpellingResult />,
    },
    {
      path: "improvements",
      element: <Improvments />,
    },
    {
      path: "result/:id",
      element: <Result />,
    },
    {
      path: "processing/:id",
      element: <Processing />,
    },
    {
      path: "my-reports",
      element: <MyReports />,
    },
    {
      path: "drafts",
      element: <Drafts />,
    },
    {
      path: "orders",
      element: <Orders />,
    },
    {
      path: "pricing",
      element: <Pricing />,
    },
    {
      path: "report/:id",
      element: <Report />,
    },
    {
      path: "payment",
      element: <Payment />,
    },
    {
      path: "return",
      element: <StripeResultReturn />,
    },
    {
      path:"transaction-complete",
      element:<TransactionComplete/>
    },
    {
      path: "exam",
      element: <Exam />,
      children: [
        {
          index: true,
          element: <ExamStepper />,
        },
        {
          path: "scoring",
          element: <Scoring />,
        },
        {
          path: "practice",
          element: <Practice />,
        },
        {
          path: "simulator",
          element: <Simulator />,
        },
      ],
    },
    {
      path: "user",
      element: <UserProfile />,
      children: [
        {
          path: "personal",
          element: <UserTabPersonal />,
        },
        {
          path: "exam-goals",
          element: <UserExamGoals />,
        },
        {
          path: "password",
          element: <UserTabPassword />,
        },
        {
          path: "settings",
          element: <UserTabSettings />,
        },
      ],
    },
  ],
};

export default MainRoutes;
