import axios from "axios";
import { storePreviousUrl } from "./storePreviousUrl";
const ignorePaths = [
  "/login",
  "/register",
  "/pricing",
  "/print",
  "/example",
  "forgot-password",
  "reset-password",
];
const axiosServices = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
});

axiosServices.interceptors.response.use(
  (response) => response,
  (error) => {
    if(!ignorePaths.some((value)=>window.location.href.includes(value))){
      console.log('test')
      window.location.pathname = '/login'
    }
    if (
      error.response.status === 403 &&
      !ignorePaths.some((value) => window.location.href.includes(value))
    ) {
      if(localStorage.getItem('serviceToken')){
        localStorage.clear()
      }
      sessionStorage.clear();
      storePreviousUrl();
      window.location.pathname = "/login";
    }
    return Promise.reject(
      (error.response && error.response.data) || "Wrong Services"
    );
  }
);

export default axiosServices;
