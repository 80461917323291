import PropTypes from "prop-types";
import { useEffect, useState } from "react";

// material-ui
import {
  Box,
  Divider,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableSortLabel,
  TableRow,
  Chip,
  Button,
  CircularProgress,
  Stack,
  ListItemText,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { visuallyHidden } from "@mui/utils";

// project-imports
import MainCard from "components/MainCard";
import moment from "moment/moment";
import { Link, useNavigate } from "react-router-dom";
import { useQueryClient } from "@tanstack/react-query";
import useGetDraftExams from "Api/hooks/useGetDraftExams";
// table data
function createData(
  id,
  date,
  service,
  exam,
  examType,
  taskType,
  status,
  operation
) {
  return {
    id,
    date,
    service,
    exam,
    examType,
    taskType,
    status,
    operation,
  };
}

// table data

// table filter
function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array?.map((el, index) => [el, index]);
  stabilizedThis?.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis?.map((el) => el[0]);
}

// table header
const headCells = [
  {
    id: "id",
    numeric: false,
    disablePadding: true,
    label: "ID",
  },
  {
    id: "Date",
    numeric: false,
    disablePadding: false,
    label: "Date",
  },
  {
    id: "Service",
    numeric: false,
    disablePadding: false,
    label: "Service",
  },
  {
    id: "Exam",
    numeric: false,
    disablePadding: false,
    label: "Exam",
  },
  {
    id: "Exam Type",
    numeric: false,
    disablePadding: false,
    label: "Exam Type",
  },
  {
    id: "Task Type",
    numeric: false,
    disablePadding: false,
    label: "Task Type",
  },
  {
    id: "status",
    numeric: false,
    disablePadding: false,
    label: "Status",
  },
  {
    id: "operation",
    numeric: false,
    disablePadding: true,
    label: "operation",
  },
  //wcex
];

function EnhancedTableHead({
  onSelectAllClick,
  order,
  orderBy,
  numSelected,
  rowCount,
  onRequestSort,
}) {
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };
  const isMobile = useMediaQuery("(max-width:500px)");

  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? "right" : "left"}
            padding={headCell.disablePadding ? "none" : "normal"}
            sortDirection={orderBy === headCell.id ? order : undefined}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === "desc" ? "sorted descending" : "sorted ascending"}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

// ==============================|| TABLE - ENHANCED ||============================== //

export default function EnhancedTable() {
  const [order, setOrder] = useState("asc");
  const isMobile = useMediaQuery("(max-width:500px)");

  const [orderBy, setOrderBy] = useState("calories");
  const [page, setPage] = useState(0);
  const [dense] = useState(false);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const { exams, isLoading } = useGetDraftExams();
  console.log(exams);
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  useEffect(() => {
    queryClient.invalidateQueries();
  }, [queryClient]);
  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };
  const rows = exams?.map((exam) =>
    createData(
      exam?.id,
      exam?.created_at,
      exam?.service_title,
      exam?.exam?.toUpperCase(),
      exam?.type?.toUpperCase(),
      exam?.writings.length > 1
        ? "TASK 1 / TASK 2"
        : `TASK ${exam?.writings && exam?.writings[0]?.task_type}`,
      exam?.status?.toUpperCase(),
      "edit"
    )
  );
  console.log(rows);
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event?.target.value, 10));
    setPage(0);
  };

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;
  if (isLoading) return <CircularProgress sx={{ margin: "auto" }} />;
  function getStatusColor(status) {
    switch (status.toLowerCase()) {
      case "draft": {
        return "warning";
      }
      case "waiting": {
        return "info";
      }
      case "done": {
        return "success";
      }
      case "error": {
        return "default";
      }
      default: {
        return "error";
      }
    }
  }
  if (isMobile && exams.length !== 0 && !isLoading) {
    return (
      <MainCard>
        <Stack>
          <Stack gap={2}>
            {stableSort(rows, getComparator(order, orderBy))
              ?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              ?.map((row, index) => {
                if (typeof row === "number") return null;
                const labelId = `enhanced-table-checkbox-${index}`;
                getStatusColor(row?.status);
                console.log(row);
                return (
                  <MainCard>
                    <Stack gap={1}>
                      <Stack direction="row" justifyContent="space-between">
                        <Typography fontWeight={700}>{row?.id}</Typography>
                        <Typography>
                          {row?.exam}&nbsp;{row?.examType}
                        </Typography>
                      </Stack>
                      <Stack
                        direction="row"
                        alignItems="center"
                        justifyContent="space-between"
                      >
                        <Typography fontWeight={500}>{row?.service}</Typography>
                        <Typography variant="caption">
                          {row?.taskType}
                        </Typography>
                        <Chip
                          color={getStatusColor(row?.status)}
                          variant="light"
                          label={row?.status}
                        />
                      </Stack>
                      <Stack gap={2}>
                        <Typography>
                          {moment(row?.date).format("MMMM Do YYYY, h:mm:ss a")}
                        </Typography>
                        <Button
                          onClick={() =>
                            navigate(
                              `/exam/${
                                row?.service == "Scrore My Writing"
                                  ? "scoring"
                                  : row?.service == "Practice"
                                  ? "practice"
                                  : "simulator"
                              }?examId=${row?.id}`
                            )
                          }
                          sx={{ color: "black" }}
                        >
                          Edit
                        </Button>
                      </Stack>
                    </Stack>
                  </MainCard>
                );
              })}
          </Stack>
          <Divider />

          <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={rows?.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Stack>
      </MainCard>
    );
  }
  return (
    <MainCard content={false}>
      {exams.length == 0 && (
        <Stack
          direction="column"
          justifyContent="space-between"
          alignItems="space-between"
          padding="2rem"
        >
          <ListItemText
            primary={
              <Typography variant="h4">No draft exams were found</Typography>
            }
            secondary={
              <Typography>
                you can save your writings for a review or a quick edit before
                finalizing it, yours looks empty as of now, but no worries! take
                an exam right now!
              </Typography>
            }
          />
          <Button
            variant="contained"
            component={Link}
            to="/exam"
            sx={{ color: "black", alignSelf: "flex-end" }}
          >
            Take an exam
          </Button>
        </Stack>
      )}
      {/* table */}
      {!exams.length == 0 && (
        <>
          <TableContainer>
            <Table
              sx={{ minWidth: 750 }}
              aria-labelledby="tableTitle"
              size={dense ? "small" : "medium"}
            >
              <EnhancedTableHead
                order={order}
                orderBy={orderBy}
                onRequestSort={handleRequestSort}
                rowCount={rows?.length}
              />
              <TableBody>
                {stableSort(rows, getComparator(order, orderBy))
                  ?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  ?.map((row, index) => {
                    if (typeof row === "number") return null;
                    const labelId = `enhanced-table-checkbox-${index}`;
                    getStatusColor(row?.status);
                    return (
                      <TableRow
                        hover
                        role="checkbox"
                        tabIndex={-1}
                        key={row.id}
                      >
                        <TableCell
                          component="th"
                          id={labelId}
                          scope="row"
                          padding="none"
                        >
                          {row.id}
                        </TableCell>
                        <TableCell align={"left"}>
                          {moment(row?.date).format("MMMM Do YYYY, h:mm:ss a")}
                        </TableCell>
                        <TableCell align={"left"}>{row?.service}</TableCell>
                        <TableCell align={"left"}>{row?.exam}</TableCell>
                        <TableCell sx={{ pr: 3 }} align={"left"}>
                          {row?.examType}
                        </TableCell>
                        <TableCell sx={{ pr: 3 }} align={"left"}>
                          {row?.taskType}
                        </TableCell>
                        <TableCell align={"left"}>
                          <Chip
                            color={getStatusColor(row?.status)}
                            variant="light"
                            label={row?.status}
                          />
                        </TableCell>
                        <TableCell align="center">
                          <Button
                            onClick={() =>
                              navigate(
                                `/exam/${
                                  row?.service == "Scrore My Writing"
                                    ? "scoring"
                                    : row?.service == "Practice"
                                    ? "practice"
                                    : "simulator"
                                }?examId=${row?.id}`
                              )
                            }
                          >
                            Edit
                          </Button>
                        </TableCell>
                      </TableRow>
                    );
                  })}
                {emptyRows > 0 && (
                  <TableRow sx={{ height: (dense ? 33 : 53) * emptyRows }}>
                    <TableCell colSpan={6} />
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
          <Divider />
          {/* table pagination */}
          <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={rows?.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </>
      )}
    </MainCard>
  );
}
