export function parsePhoneNumber(phoneStr) {
  if (!phoneStr || !phoneStr.includes("-")) {
    return { code: null, number: null };
  }

  const [code, number] = phoneStr.split("-");

  if (isNaN(code) || isNaN(number)) {
    return { code: null, number: null };
  }

  const result = {
    code: parseInt(code, 10),
    number: number,
  };

  return result;
}
