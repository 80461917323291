import { CircularProgress } from "@mui/material";
import useUser from "Api/hooks/useUser";
import TransactionComplete from "components/transactions/TransactionComplete";
import { enqueueSnackbar } from "notistack";
import { useContext, useState } from "react";
import { Navigate } from "react-router";
import axios from "utils/axios";
export default function useStripePayment() {
  const [status, setStatus] = useState();
  const [orderDetails, setOrderDetails] = useState();
  function getPaymentStatus(sessionId) {
    axios.post("/status", { session_id: sessionId }).then((res) => {
      setStatus(res.data.data.status);
      setOrderDetails(res.data.data);
      console.log(res.data.data);
    });
  }
  function finalizePayment(status, sessionId) {
    if (!orderDetails) return;
    switch (status) {
      case "open": {
        setInterval(() => getPaymentStatus(sessionId), 5000);
        return <CircularProgress sx={{ margin: "auto" }} />;
      }
      case "failed": {
        enqueueSnackbar({
          variant: "error",
          message:
            "There was an error completing your payment, please try again",
        });
        const writingPackageUrl = `/payment?plan_id=${orderDetails.plan_id}`;
        const examPackageUrl = `/payment?exam_id=${orderDetails.exam_id}`;
        return (
          <Navigate
            to={orderDetails.plan_id ? writingPackageUrl : examPackageUrl}
          />
        );
      }
      case "done": {
        return orderDetails.exam_id != "" ? (
          <Navigate to={`/processing/${orderDetails.exam_id}`} />
        ) : (
          <TransactionComplete
            planId={orderDetails.plan_id}
            transactionId={orderDetails.transaction}
          />
        );
      }
      default: {
        setInterval(() => getPaymentStatus(sessionId), 5000);
      }
    }
  }
  return { getPaymentStatus, status, finalizePayment ,orderDetails};
}
