import {
  Button,
  FormHelperText,
  Grid,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { useNavigate } from "react-router";
import MainCard from "../../MainCard";
import { Formik } from "formik";
import { countwords } from "utils/contwords";
import { ArrowLeft } from "iconsax-react";
import TaskImage from "../TaskImage";
import { useState } from "react";
import { validateSimulatorExam } from "../examUtils";

export default function SimulatorTaskForm({
  task,
  onSubmit,
  currentTask,
  welcomeModalOpen,
  isEdit,
  setCurrentTask,
  examData,
  previousTask,formRef
}) {
  const navigate = useNavigate();
  const [previewImage, setPreviewImage] = useState(false);
  return (
    <Grid
      container
      spacing={2}
      sx={{ filter: `${welcomeModalOpen ? "blur(20px)" : ""}` }}
    >
      <Grid item xs={12} sm={6}>
        <MainCard
          sx={{ maxHeight: "500px", overflow: "scroll" }}
          title={
            <Stack direction="row" justifyContent="space-between">
              <Typography variant="subtitle1">
                Task {task?.task_type}
              </Typography>
              <Typography variant="subtitle1">
                IELTS {task?.exam_type?.toUpperCase()}
              </Typography>
            </Stack>
          }
        >
          <Typography
            dangerouslySetInnerHTML={{
              __html: task && task?.task_description,
            }}
          ></Typography>
          {task?.exam_type == "academic" && task?.task_type == 1 && (
            <TaskImage
              imgSrc={isEdit ? task && task?.file : task && task?.image}
              setPreviewImage={setPreviewImage}
              previewImage={previewImage}
            />
          )}
        </MainCard>
      </Grid>
      <Grid item xs={12} sm={6}>
        <Formik
        innerRef={formRef}
          onSubmit={(values) => onSubmit(values)}
          enableReinitialize={isEdit ? true : false}
          initialValues={{
            answer: isEdit
              ? task?.task_answer
              : currentTask == 1
              ? examData.task1_answer
              : examData.task2_answer,
          }}
          validate={(values) => validateSimulatorExam(values, isEdit,currentTask)}
        >
          {({ errors, handleChange, handleSubmit, values, touched }) => (
            <>
              <form onSubmit={handleSubmit}>
                <MainCard
                  sx={{ height: "500px" }}
                  title={
                    <Stack direction="row" justifyContent="space-between">
                      <Typography variant="subtitle1">Task answer</Typography>
                      <Typography>
                        {countwords(values.answer || "")} words
                      </Typography>
                    </Stack>
                  }
                  subheader="Enter your task answer here"
                >
                  <TextField
                    id="answer"
                    multiline
                    rows={15}
                    name="answer"
                    fullWidth
                    value={values.answer}
                    onChange={handleChange}
                    disabled={isEdit}
                  />
                  {touched.answer && errors.answer && (
                    <FormHelperText
                      error
                      id="standard-weight-helper-text-answer"
                    >
                      {errors.answer}
                    </FormHelperText>
                  )}
                </MainCard>
                <Stack
                  direction="row"
                  width="100%"
                  justifyContent="space-between"
                  sx={{ marginTop: "1rem" }}
                >
                  <Button
                    variant="contained"
                    color="error"
                    onClick={() => navigate("/exam?simulator")}
                  >
                    <ArrowLeft />
                  </Button>
                  {currentTask != 1 && (
                    <Button
                      variant="contained"
                      onClick={()=>previousTask(values)}
                    >
                      Previous Task
                    </Button>
                  )}
                  <Button variant="contained" type="submit">
                    {currentTask == 1 ? "Next Task" : "Score my writing"}
                  </Button>
                </Stack>
              </form>
            </>
          )}
        </Formik>
      </Grid>
    </Grid>
  );
}
