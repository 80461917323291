import {
  Button,
  CircularProgress,
  FormHelperText,
  Grid,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { useGetTask } from "Api/hooks/useGetTask";
import React, { useEffect, useRef, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import MainCard from "../../MainCard";
import { countwords } from "utils/contwords";
import { Formik } from "formik";
import { ArrowLeft, Refresh } from "iconsax-react";
import { useAutoSave } from "Api/hooks/useAutoSave";
import { usePostWriting } from "Api/hooks/usePostWriting";
import ExitModal from "../../ExitModal";
import ExamServicesHeader from "../ExamServicesHeader";
import TaskImage from "../TaskImage";
import useGetEditedExam from "Api/hooks/useGetEditedExam";
import { createPracticeData, validatePracticeExam } from "../examUtils";

function PracticeForm() {
  const [searchParams] = useSearchParams();
  const [isPaused, setIsPaused] = useState(true);
  const exam_id = searchParams.get("examId");
  const { editedExam, isEditedExamLoading } = useGetEditedExam(exam_id);
  const exam_type = searchParams.get("exam_type") || editedExam?.type;
  const task_type =
    searchParams.get("task_type") || editedExam?.writings[0]?.task_type;

  const [submitType, setSubmitType] = useState();
  const formRef = useRef();

  const [exitModal, setExitModal] = useState(false);
  const navigate = useNavigate();

  const { autoSave, lastSaved } = useAutoSave();
  const { postWriting, isPosting } = usePostWriting(submitType);
  const autoSaveIdRef = useRef(exam_id || null);
  const [taskDuration, setTaskDuration] = useState(0);
  const durationRef = useRef(0);

  const [isFirstInput, setIsFirstInput] = useState(true);
  const [previewImage, setPreviewImage] = useState(false);

  const { task, isLoading, isFetching, error, refetch } = useGetTask({
    exam_type,
    task_type,
    exam_id,
  });

  function handleQuit() {
    if (
      exam_id &&
      editedExam?.writings[0]?.task_answer == formRef.current.values.answer
    ) {
      navigate("/drafts");
      return;
    }
    if (formRef.current.values.answer == "") {
      navigate("/exam");
      return;
    }
    setExitModal(true);
  }

  useEffect(() => {
    durationRef.current = taskDuration;
  }, [taskDuration]);

  useEffect(() => {
    if (!editedExam) return;
    setTaskDuration(editedExam?.writings[0]?.duration);
  }, [editedExam]);

  function submitHandler(values) {
    if(values.answer == "") return
    const examData = createPracticeData(
      task
        ? task[0]?.task_description
        : editedExam?.writings[0]?.task_description,
      task ? task[0]?.id : editedExam?.writings[0]?.id,
      values.answer,
      taskDuration,
      task_type,
      Number(autoSaveIdRef.current),
      exam_type,
      exam_id
    );
    console.log(examData)
    postWriting(examData);
  }

  function autoSaveHandler() {
    const answer = formRef?.current?.values?.answer;
    if (answer == "") return;
    const examData = createPracticeData(
      task
        ? task[0]?.task_description
        : editedExam?.writings[0]?.task_description,
      task ? task[0]?.id : editedExam?.writings[0]?.id,
      answer,
      durationRef.current,
      task_type,
      Number(autoSaveIdRef.current),
      exam_type,
      exam_id
    );
    autoSave(examData, {
      onSuccess: (data) => (autoSaveIdRef.current = data?.id),
    });
  }

  useEffect(() => {
    console.log("not working")
    console.log('working')
    const interval = setInterval(() => autoSaveHandler(), 20000);
    return () => clearInterval(interval);
  }, [task, editedExam]);

  const handleFirstInput = (event) => {
    if (isFirstInput) {
      setIsPaused(false);
      setIsFirstInput(false);
    }
  };

  useEffect(() => {
    if (isPaused) {
      setIsFirstInput(true);
    }
  }, [isPaused]);

  if (isLoading || isFetching || isPosting || isEditedExamLoading)
    return <CircularProgress sx={{ margin: "auto" }} />;
  return (
    <Formik
      innerRef={formRef}
      enableReinitialize={true}
      initialValues={{
        answer: editedExam?.writings[0]?.task_answer || "",
      }}
      validate={(values) => validatePracticeExam(values, submitType)}
      onSubmit={(values) => submitHandler(values)}
    >
      {({
        errors,
        handleChange,
        handleSubmit,
        touched,
        values,
        setFieldValue,
      }) => (
        <>
          <Stack>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <ExamServicesHeader
                  isPaused={isPaused}
                  setIsPaused={setIsPaused}
                  currentTask={task_type}
                  setTaskDuration={setTaskDuration}
                  passedTime={editedExam?.writings[0]?.duration}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <MainCard
                  sx={{ maxHeight: "500px", overflow: "scroll" }}
                  title={
                    <Stack direction="row" justifyContent="space-between">
                      <Typography variant="subtitle1">
                        Task {task_type}
                      </Typography>
                      <Typography variant="subtitle1">
                        Exam {exam_type.toUpperCase()}
                      </Typography>
                    </Stack>
                  }
                >
                  <Typography
                    dangerouslySetInnerHTML={{
                      __html: task
                        ? task[0]?.task_description
                        : editedExam?.writings[0]?.task_description,
                    }}
                  ></Typography>
                  {exam_type == "academic" && task_type == 1 && (
                    <TaskImage
                      imgSrc={
                        task
                          ? task[0]?.image
                          : editedExam
                          ? editedExam?.writings[0]?.file
                          : null
                      }
                      previewImage={previewImage}
                      setPreviewImage={setPreviewImage}
                    />
                  )}
                </MainCard>
                {!exam_id && (
                  <Button
                    sx={{ marginTop: "1rem" }}
                    onClick={() => {
                      refetch();
                    }}
                    endIcon={<Refresh />}
                    variant="contained"
                    color="secondary"
                  >
                    Change my question
                  </Button>
                )}
              </Grid>
              <Grid item xs={12} sm={6}>
                <form onSubmit={handleSubmit} noValidate>
                  <MainCard
                    sx={{ height: "500px" }}
                    title={
                      <Stack direction="row" justifyContent="space-between">
                        <Typography variant="subtitle1">Task answer</Typography>
                        <Typography>
                          {countwords(values.answer || "")} words
                        </Typography>
                      </Stack>
                    }
                    subheader="Enter your task answer here"
                  >
                    <TextField
                      id="answer"
                      multiline
                      rows={15}
                      name="answer"
                      fullWidth
                      value={values.answer}
                      onChange={handleChange}
                      onInput={handleFirstInput}
                    />
                    {touched.answer && errors.answer && (
                      <FormHelperText
                        error
                        id="standard-weight-helper-text-answer"
                      >
                        {errors.answer}
                      </FormHelperText>
                    )}
                    <Typography variant="caption" color="GrayText">
                      {lastSaved ? `Last saved ${lastSaved}` : null}
                    </Typography>
                  </MainCard>
                  <Stack
                    direction="row"
                    width="100%"
                    justifyContent="space-between"
                    sx={{ marginTop: "1rem" }}
                  >
                    <Button
                      variant="contained"
                      color="error"
                      onClick={() => handleQuit()}
                    >
                      <ArrowLeft />
                    </Button>
                    <Stack direction="row" gap="1rem">
                      <Button
                        variant="outlined"
                        type="submit"
                        size="small"
                        onClick={() => setSubmitType("draft")}
                      >
                        Save as draft
                      </Button>
                      <Button
                        variant="contained"
                        size="small"
                        type="submit"
                        onClick={() => {
                          setSubmitType("final-submission");
                        }}
                      >
                        Score my writing
                      </Button>
                    </Stack>
                  </Stack>
                </form>
              </Grid>
            </Grid>
          </Stack>
          <ExitModal
            save={() => autoSaveHandler()}
            open={exitModal}
            setOpen={setExitModal}
          />
        </>
      )}
    </Formik>
  );
}

export default PracticeForm;
