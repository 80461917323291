import { useState } from "react";

// material-ui
import {
  Backdrop,
  Button,
  Divider,
  Fade,
  CardContent,
  Modal,
  Stack,
  Typography,
} from "@mui/material";

// project-imports
import MainCard from "components/MainCard";
import WelcomeTimer from "components/exam/IELTSsimulator/WelcomeTimer";
import { useNavigate } from "react-router";
import { Link } from "react-router-dom";

// ==============================|| MODAL - TRANSITIONS ||============================== //

export default function VerifyEmailModal({ open, close }) {
  return (
    <Modal
      open={open}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      sx={{
        "& .MuiModal-backdrop": {
          backgroundColor: "rgba(0, 0, 0, 0.5);",
        },
      }}
    >
      <Fade in={open} sx={{ border: "none", outline: "none" }}>
        <MainCard
          title="Verify your email and get a free writing"
          modal
          darkTitle
          content={false}
          sx={{ backgroundColor: "red", outline: "none", border: "none" }}
        >
          <CardContent
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <Stack
              direction="row"
              width="100%"
              alignItems="center"
              justifyContent="space-between"
              spacing={4}
            >
              <Typography variant="subtitle1">
                Confirm your email address now and enjoy a complimentary writing
                correction on us! It's quick and easy—verify your email to
                unlock your free correction and enhance your writing skills
                today.
              </Typography>
            </Stack>
          </CardContent>
          <Divider />
          <Stack
            direction="row"
            justifyContent="space-between"
            width="100%"
            alignItems="center"
            sx={{ p: "1rem" }}
          >
            <Button
              color="secondary"
              onClick={close}
              sx={{ textTransform: "none" }}
            >
              Skip for now
            </Button>
            <Button
              variant="contained"
              LinkComponent={Link}
              onClick={close}
              to="/email-confirmation?resend=true"
              sx={{ color: "black", textTransform: "none" }}
            >
              Verfiy my email
            </Button>
          </Stack>
        </MainCard>
      </Fade>
    </Modal>
  );
}
