export const countryCodes = [
    { name: "AF", code: "+93" },
    { name: "AL", code: "+355" },
    { name: "DZ", code: "+213" },
    { name: "AD", code: "+376" },
    { name: "AO", code: "+244" },
    { name: "AG", code: "+1-268" },
    { name: "AR", code: "+54" },
    { name: "AM", code: "+374" },
    { name: "AU", code: "+61" },
    { name: "AT", code: "+43" },
    { name: "AZ", code: "+994" },
    { name: "BS", code: "+1-242" },
    { name: "BH", code: "+973" },
    { name: "BD", code: "+880" },
    { name: "BB", code: "+1-246" },
    { name: "BY", code: "+375" },
    { name: "BE", code: "+32" },
    { name: "BZ", code: "+501" },
    { name: "BJ", code: "+229" },
    { name: "BT", code: "+975" },
    { name: "BO", code: "+591" },
    { name: "BA", code: "+387" },
    { name: "BW", code: "+267" },
    { name: "BR", code: "+55" },
    { name: "BN", code: "+673" },
    { name: "BG", code: "+359" },
    { name: "BF", code: "+226" },
    { name: "BI", code: "+257" },
    { name: "CV", code: "+238" },
    { name: "KH", code: "+855" },
    { name: "CM", code: "+237" },
    { name: "CA", code: "+1" },
    { name: "CF", code: "+236" },
    { name: "TD", code: "+235" },
    { name: "CL", code: "+56" },
    { name: "CN", code: "+86" },
    { name: "CO", code: "+57" },
    { name: "KM", code: "+269" },
    { name: "CD", code: "+243" },
    { name: "CG", code: "+242" },
    { name: "CR", code: "+506" },
    { name: "HR", code: "+385" },
    { name: "CU", code: "+53" },
    { name: "CY", code: "+357" },
    { name: "CZ", code: "+420" },
    { name: "DK", code: "+45" },
    { name: "DJ", code: "+253" },
    { name: "DM", code: "+1-767" },
    { name: "DO", code: "+1-809" },
    { name: "DO", code: "+1-829" },
    { name: "DO", code: "+1-849" },
    { name: "EC", code: "+593" },
    { name: "EG", code: "+20" },
    { name: "SV", code: "+503" },
    { name: "GQ", code: "+240" },
    { name: "ER", code: "+291" },
    { name: "EE", code: "+372" },
    { name: "SZ", code: "+268" },
    { name: "ET", code: "+251" },
    { name: "FJ", code: "+679" },
    { name: "FI", code: "+358" },
    { name: "FR", code: "+33" },
    { name: "GA", code: "+241" },
    { name: "GM", code: "+220" },
    { name: "GE", code: "+995" },
    { name: "DE", code: "+49" },
    { name: "GH", code: "+233" },
    { name: "GR", code: "+30" },
    { name: "GD", code: "+1-473" },
    { name: "GT", code: "+502" },
    { name: "GN", code: "+224" },
    { name: "GW", code: "+245" },
    { name: "GY", code: "+592" },
    { name: "HT", code: "+509" },
    { name: "HN", code: "+504" },
    { name: "HU", code: "+36" },
    { name: "IS", code: "+354" },
    { name: "IN", code: "+91" },
    { name: "ID", code: "+62" },
    { name: "IR", code: "+98" },
    { name: "IQ", code: "+964" },
    { name: "IE", code: "+353" },
    { name: "IL", code: "+972" },
    { name: "IT", code: "+39" },
    { name: "JM", code: "+1-876" },
    { name: "JP", code: "+81" },
    { name: "JO", code: "+962" },
    { name: "KZ", code: "+7" },
    { name: "KE", code: "+254" },
    { name: "KI", code: "+686" },
    { name: "KW", code: "+965" },
    { name: "KG", code: "+996" },
    { name: "LA", code: "+856" },
    { name: "LV", code: "+371" },
    { name: "LB", code: "+961" },
    { name: "LS", code: "+266" },
    { name: "LR", code: "+231" },
    { name: "LY", code: "+218" },
    { name: "LI", code: "+423" },
    { name: "LT", code: "+370" },
    { name: "LU", code: "+352" },
    { name: "MG", code: "+261" },
    { name: "MW", code: "+265" },
    { name: "MY", code: "+60" },
    { name: "MV", code: "+960" },
    { name: "ML", code: "+223" },
    { name: "MT", code: "+356" },
    { name: "MH", code: "+692" },
    { name: "MR", code: "+222" },
    { name: "MU", code: "+230" },
    { name: "MX", code: "+52" },
    { name: "FM", code: "+691" },
    { name: "MD", code: "+373" },
    { name: "MC", code: "+377" },
    { name: "MN", code: "+976" },
    { name: "ME", code: "+382" },
    { name: "MA", code: "+212" },
    { name: "MZ", code: "+258" },
    { name: "MM", code: "+95" },
    { name: "NA", code: "+264" },
    { name: "NR", code: "+674" },
    { name: "NP", code: "+977" },
    { name: "NL", code: "+31" },
    { name: "NZ", code: "+64" },
    { name: "NI", code: "+505" },
    { name: "NE", code: "+227" },
    { name: "NG", code: "+234" },
    { name: "KP", code: "+850" },
    { name: "MK", code: "+389" },
    { name: "NO", code: "+47" },
    { name: "OM", code: "+968" },
    { name: "PK", code: "+92" },
    { name: "PW", code: "+680" },
    { name: "PA", code: "+507" },
    { name: "PG", code: "+675" },
    { name: "PY", code: "+595" },
    { name: "PE", code: "+51" },
    { name: "PH", code: "+63" },
    { name: "PL", code: "+48" },
    { name: "PT", code: "+351" },
    { name: "QA", code: "+974" },
    { name: "RO", code: "+40" },
    { name: "RU", code: "+7" },
    { name: "RW", code: "+250" },
    { name: "KN", code: "+1-869" },
    { name: "LC", code: "+1-758" },
    { name: "VC", code: "+1-784" },
    { name: "WS", code: "+685" },
    { name: "SM", code: "+378" },
    { name: "ST", code: "+239" },
    { name: "SA", code: "+966" },
    { name: "SN", code: "+221" },
    { name: "RS", code: "+381" },
    { name: "SC", code: "+248" },
    { name: "SL", code: "+232" },
    { name: "SG", code: "+65" },
    { name: "SK", code: "+421" },
    { name: "SI", code: "+386" },
    { name: "SB", code: "+677" },
    { name: "SO", code: "+252" },
    { name: "ZA", code: "+27" },
    { name: "KR", code: "+82" },
    { name: "SS", code: "+211" },
    { name: "ES", code: "+34" },
    { name: "LK", code: "+94" },
    { name: "SD", code: "+249" },
    { name: "SR", code: "+597" },
    { name: "SE", code: "+46" },
    { name: "CH", code: "+41" },
    { name: "SY", code: "+963" },
    { name: "TW", code: "+886" },
    { name: "TJ", code: "+992" },
    { name: "TZ", code: "+255" },
    { name: "TH", code: "+66" },
    { name: "TL", code: "+670" },
    { name: "TG", code: "+228" },
    { name: "TO", code: "+676" },
    { name: "TT", code: "+1-868" },
    { name: "TN", code: "+216" },
    { name: "TR", code: "+90" },
    { name: "TM", code: "+993" },
    { name: "TV", code: "+688" },
    { name: "UG", code: "+256" },
    { name: "UA", code: "+380" },
    { name: "AE", code: "+971" },
    { name: "GB", code: "+44" },
    { name: "US", code: "+1" },
    { name: "UY", code: "+598" },
    { name: "UZ", code: "+998" },
    { name: "VU", code: "+678" },
    { name: "VA", code: "+39-06" },
    { name: "VE", code: "+58" },
    { name: "VN", code: "+84" },
    { name: "YE", code: "+967" },
    { name: "ZM", code: "+260" },
    { name: "ZW", code: "+263" }
];
