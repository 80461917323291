import axios from "utils/axios";

export function verifyEmailApi(code) {
  return axios.post("/verify-email", { code }).then(({ data }) => data.data);
}
export function resendEmailVerificationCode() {
  return axios.post("/send-verify-email").then(({ data }) => data.data);
}
export function resetPasswordCode(email) {
  return axios
    .post("/email-reset-password", { email })
    .then(({ data }) => data.data);
}

export function resetPasswordApi(data) {
  return axios.post("/reset-password", data).then(({ data }) => data.data);
}
export function updatePasswodApi(data) {
  return axios.post("/password", data).then(({ data }) => data.data);
}
