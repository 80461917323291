import { useQuery, useQueryClient } from "@tanstack/react-query";
import { getTaskApi } from "Api/providers/taskProvider";
import { useEffect } from "react";

export function useGetTask({ exam_type, task_type, exam_id }) {
  const {
    data: task,
    isLoading,
    isFetching,
    error,
    refetch,
  } = useQuery({
    queryKey: [`task`],
    keepPreviousData: false,
    queryFn: () => getTaskApi({ exam_type, task_type }),
    retry: false,
    staleTime: 0,
    gcTime: 0,
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
    enabled: exam_id ? false : true,
  });
  return { task, isLoading, isFetching, error, refetch };
}
