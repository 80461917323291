export function createReportTableData(
  id,
  date,
  service,
  exam,
  examType,
  status,
  operation,
  taskType
) {
  return {
    id,
    date,
    service,
    exam,
    examType,
    status,
    operation,
    taskType
  };
}
