import { useEffect, useRef, useState } from "react";

// material-ui
import { useTheme } from "@mui/material/styles";

// third-party
import ReactApexChart from "react-apexcharts";

// project import
import { ThemeMode } from "config";
import moment from "moment";

// chart options
const lineChartOptions = {
  chart: {
    height: 350,
    type: "line",
    zoom: {
      enabled: false,
    },
  },
  dataLabels: {
    enabled: false,
  },
  stroke: {
    curve: "straight",
  },
  xaxis: {
    categories: ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep"],
  },
  legend: {
    show: false,
  },
};

// ==============================|| APEXCHART - LINE ||============================== //
const ApexLineChart = ({ scores }) => {
  const categories = scores?.map((score) =>
    moment(score?.date).format("MMM Do")
  );
  const overallScores = scores?.map((score) => Number(score?.score).toFixed(1));
  const grammaticalScores = scores?.map((score) =>
    Number(score?.grammatical).toFixed(1)
  );
  const lexicalScores = scores?.map((score) =>
    Number(score?.lexical).toFixed(1)
  );
  const coherenceScores = scores?.map((score) =>
    Number(score?.coherence).toFixed(1)
  );
  const achievementScores = scores?.map((score) =>
    Number(score?.task_achievement).toFixed(1)
  );
  const theme = useTheme();
  const mode = theme.palette.mode;

  const line = theme.palette.divider;
  const { primary } = theme.palette.text;
  const grey200 = theme.palette.secondary[200];
  const secondary = theme.palette.primary[700];
  const ALL_SERIES = [
    {
      name: "Overall score",
      color: "#f97316",
      serie: { name: "Overall score", data: overallScores },
    },
    {
      name: "Grammatical score",
      color: "#22c55e",
      serie: { name: "Grammatical score", data: grammaticalScores },
    },
    {
      name: "Lexical score",
      color: "#06b6d4",
      serie: { name: "Lexical score", data: lexicalScores },
    },
    {
      name: "Coherence score",
      color: "#8b5cf6",
      serie: { name: "Coherence score", data: coherenceScores },
    },
    {
      name: "Task achievement score",
      color: "#042f2e",
      serie: { name: "Task achievement score", data: achievementScores },
    },
  ];
  const colorsRef = useRef(["#f97316"]);
  const [series, setSeries] = useState([
    {
      name: "Overall score",
      data: overallScores,
    },
    // {
    //   name: "Grammatical score",
    //   data: grammaticalScores,
    // },
    // {
    //   name: "Lexical score",
    //   data: lexicalScores,
    // },
    // {
    //   name: "Coherence score",
    //   data: coherenceScores,
    // },
    // {
    //   name: "Task achievement score",
    //   data: achievementScores,
    // },
  ]);

  const [options, setOptions] = useState(lineChartOptions);
  console.log(series, colorsRef.current, options);
  function toggleSeries(name, serieObj) {
    const index = series.findIndex((obj) => obj.name === name);

    if (index === -1) {
      const newSeries = serieObj.serie;
      setSeries([...series, newSeries]);
      colorsRef.current.push(serieObj.color); // Add new color to colorsRef.current
    } else {
      const updatedSeries = series.filter((obj) => obj.name !== name);
      const updatedColors = colorsRef.current.filter(
        (color) => color !== serieObj.color
      ); // Remove color from colorsRef.current
      setSeries(updatedSeries);
      colorsRef.current = updatedColors;
    }
    setOptions((prev) => ({ ...prev,colors:colorsRef.current }));
  }
console.log(series.find((item)=>item.name == 'Overall scrore'))
  useEffect(() => {
    setOptions((prevState) => ({
      ...prevState,
      colors: colorsRef.current,
      dataLabels: {
        enabled: true,
        style: {
          fontSize: "12px",
          fontWeight: "bold",
        },
        background: {
          enabled: true,
          foreColor: "#fff",
          borderRadius: 2,
          padding: 4,
          opacity: 0.9,
          borderWidth: 1,
          borderColor: "#fff",
        },
        offsetY: -7, // adjust the vertical position of the label
      },
      markers: {
        size: 6,
        strokeWidth: 2,
        hover: {
          size: 9,
        },
      },
      xaxis: {
        categories,
        labels: {
          style: {
            colors: [
              primary,
              primary,
              primary,
              primary,
              primary,
              primary,
              primary,
              primary,
              primary,
            ],
          },
        },
      },
      yaxis: {
        max: 9,
        labels: {
          style: {
            colors: [primary],
          },
        },
      },
      grid: {
        borderColor: line,
      },
      theme: {
        mode: mode === ThemeMode.DARK ? "dark" : "light",
      },
    }));
  }, [mode, primary, line, grey200, secondary]);

  return (
    <div id="chart">
      <ReactApexChart
        options={options}
        series={series}
        type="line"
        height={350}
      />
      <ul className="chart-legends">
        {ALL_SERIES.map((serie) => (
          <li
            className={`chart-legend ${series.find((item)=>item.name == serie.name)? "active" : ""}`}
            onClick={() => toggleSeries(serie.name, serie)}
          >
            <span
              className="bullet-point"
              style={{ backgroundColor: serie.color }}
            ></span>
            {serie.name}
          </li>
        ))}
      </ul>
    </div>
  );
};

export default ApexLineChart;
