export function createScoringData(
  description,
  answer,
  type,
  id,
  exam_type,
  file = null,
  hasFile
) {
  const taskData = {
    description,
    answer,
    exam_type,
  };

  return {
    [`task${type}_description`]: taskData.description,
    [`task${type}_answer`]: taskData.answer,
    exam_type,
    service: "score_my_writing",
    status: "draft",
    ...(hasFile && { file }),
    id,
  };
}

export function createPracticeData(
  description,
  taskId,
  answer,
  duration,
  type,
  id,
  exam_type,
  exam_id
) {
  const taskData = {
    description,
    answer,
    exam_type,
    id: taskId,
    duration,
  };

  return {
    [`task${type}_description`]: taskData.description,
    [`task${type}_answer`]: taskData.answer,
    ...(!exam_id && { [`task${type}_id`]: taskData.id }),
    [`task${type}_duration`]: taskData.duration,
    exam_type,
    service: "practice",
    status: "draft",
    id,
  };
}

export function validateScoringExam(values, submitType) {
  if (submitType == "draft") return;
  let errors = {};
  if (!values.answer) {
    errors.answer = "Task Answer is required";
  } else if (
    values.answer.split(" ").filter((word) => word !== "").length < 50
  ) {
    errors.answer = "You should write at least 50 words";
  }
  if (!values.description) {
    errors.description = "Task description is reqired";
  }
  return errors;
}

export function validatePracticeExam(values, submitType) {
  if (submitType === "draft") return;
  let errors = {};
  if (!values.answer) {
    errors.answer = "Task Answer is required";
  } else if (
    values.answer.split(" ").filter((word) => word !== "").length < 50
  ) {
    errors.answer = "You should write at least 50 words";
  }
  return errors;
}

export function validateSimulatorExam(values, isEdit,currentTask) {
  if (isEdit) return;
  if(currentTask == 1)return;
  let errors = {};
  if (!values.answer) {
    errors.answer = "Task Answer is required";
  } else if (
    values.answer.split(" ").filter((word) => word !== "").length < 50
  ) {
    errors.answer = "You should write at least 50 words";
  }
  return errors;
}
