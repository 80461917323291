import {
  Alert,
  Button,
  Grid,
  ListItemText,
  Stack,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import useProfile from "Api/hooks/useProfile";
import useUser from "Api/hooks/useUser";
import FeaturesBreadcrumbs from "components/FeatureBreadcrumbs";
import EcommerceDataCard from "components/cards/statistics/EcommerceDataCard";
import {
  ArchiveBook,
  Book,
  Book1,
  Chart1,
  Chart21,
  Global,
  InfoCircle,
  TableDocument,
} from "iconsax-react";
import { useEffect, useState } from "react";
import { Outlet, useNavigate, useParams } from "react-router";
import { Link } from "react-router-dom";
export const examOptions = [
  {
    name: "exam_type",
    logo: <ArchiveBook size={32} />,
    description: "IELTS academic",
    value: "academic",
  },
  {
    name: "exam_type",
    logo: <Global size={32} />,
    description: "IELTS general",
    value: "general",
  },
];
export const taskOptions = [
  {
    name: "task_type",
    logo: <Chart21 size={32} />,
    description: "Task 1",
    value: 1,
  },
  {
    name: "task_type",
    logo: <Chart1 size={32} />,
    description: "Task 2",
    value: 2,
  },
];
function Exam() {
  const params = useParams();
  const pathname = window.location.pathname;
  console.log(pathname.split("/"));
  return (
    <>
      <FeaturesBreadcrumbs />
      <Outlet />
    </>
  );
}

export default Exam;
export function ExamStepper() {
  const navigate = useNavigate();
  const theme = useTheme();
  const {user} = useUser()
const {profile} = useProfile()
  const isMobile = useMediaQuery("(max-width:500px)");
  const [buyModalOpen, setBuyModalOpen] = useState(false);
  function handleStartExam(url, credit) {
    const hasEnoughCredits = user?.user?.credit == credit;
    if (hasEnoughCredits) {
      navigate(url);
      return;
    }
  }
  return (
    <>
      <Stack>
        <ListItemText
          secondary={
            <Typography variant="h4" color={theme.palette.secondary.main}>
              To get started, Please choose one of our services
            </Typography>
          }
          primary={
            <Typography variant="h3">
              We are more than happy to assist you in your journey
            </Typography>
          }
        />
        {!user?.user?.email_is_confirmed && (
          <div
            style={{
              borderRadius: "8px",
              width: "100%",
              backgroundColor: "#16a34a",
              color: "white",
              padding: "1rem",
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              flexDirection: isMobile ? "column" : "row",
              gap: "0.4rem",
              marginTop: "1rem",
            }}
          >
            <span
              style={{ display: "flex", alignItems: "center", gap: "0.3rem" }}
            >
              <InfoCircle style={{ flexShrink: 0 }} />
              <span>
                <Typography variant="h5">Verify your email</Typography>
                <Typography>
                  Confirm your email and get a free writing credit!{" "}
                </Typography>
              </span>
            </span>
            <Button
              fullWidth={isMobile ? true : false}
              variant="contained"
              LinkComponent={Link}
              to="/email-confirmation"
              sx={{ bgcolor: "#14532d" }}
            >
              Verify
            </Button>
          </div>
        )}
        <Grid
          container
          spacing={3}
          justifyContent="center"
          sx={{ marginTop: "0.3rem" }}
        >
          <Grid item xs={12} sm={4}>
            <EcommerceDataCard
              credit={1}
              examService
              title="Score my writing"
              color="success"
              iconPrimary={<Book />}
              onClick={() => navigate("scoring")}
            >
              <Typography>
                Send us a question and your answer, and we'll score and correct
                it for you.
              </Typography>
            </EcommerceDataCard>
          </Grid>
          <Grid item xs={12} sm={4}>
            <EcommerceDataCard
              credit={1}
              examService
              title="Practice"
              color="warning"
              iconPrimary={<TableDocument />}
              onClick={() => navigate("practice")}
            >
              <Typography>
                We'll give you a random question to answer, and then we'll score
                and correct it.
              </Typography>
            </EcommerceDataCard>
          </Grid>
          <Grid item xs={12} sm={4}>
            <EcommerceDataCard
              credit={2}
              examService
              title="IELTS Simulator"
              color="info"
              onClick={() => navigate("simulator")}
              iconPrimary={<Book1 />}
            >
              <Typography>
                Take a mock IELTS exam in a simulated environment. You'll have
                60 minutes to answer task 1 and task 2, and we'll score and
                correct your answers.
              </Typography>
            </EcommerceDataCard>
          </Grid>
        </Grid>
      </Stack>
    </>
  );
}
