import {
  Button,
  Checkbox,
  CircularProgress,
  Fade,
  FormControlLabel,
  FormGroup,
  Modal,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import useContact from "Api/hooks/useContact";
import useUser from "Api/hooks/useUser";
import MainCard from "components/MainCard";
import { enqueueSnackbar } from "notistack";
import React, { useEffect, useRef, useState } from "react";
import { FaTimes } from "react-icons/fa";
export const PAYMNET_PROBLEMS = [
  { id: 1, title: "  I don't have credit/debit card", type: "no_card" },
  { id: 2, title: "Card declined", type: "card_declined" },
  { id: 3, title: "Error message displayed", type: "error" },
  { id: 4, title: "Unable to enter card details", type: "input_error" },
  { id: 5, title: "Other", type: "other" },
];
function PaymentFeedback() {
  const { user } = useUser();
  const { sendMessage, isSending } = useContact();
  const [open, setOpen] = useState(false);
  const [otherToggled, setOtherToggled] = useState();
  const [otherValue, setOtherValue] = useState("");
  const [state, setState] = useState({
    other: false,
    input_error: false,
    error: false,
    card_declined: false,
    no_card: false,
  });
  const getTitlesOfTrueStateKeys = (state, problems) => {
    const titles = problems
      .filter((problem) => state[problem.type])
      .map((problem) => {
        if (problem.type === "other") {
          return otherValue;
        }
        return problem.title;
      });

    return titles;
  };
  const handleChange = (event) => {
    if (event.target.name === "other") {
      if (event.target.checked) {
        setOtherToggled(true);
      } else {
        setOtherToggled(false);
      }
    }
    setState({
      ...state,
      [event.target.name]: event.target.checked,
    });
  };
  console.log(state);
  function handlePaymentIssue() {
    const issues = getTitlesOfTrueStateKeys(state, PAYMNET_PROBLEMS);
    const messageData = {
      fullname: user?.user?.name,
      email: user?.user?.email,
      message: issues.join(),
    };
    sendMessage(messageData, {
      onSuccess: () => {
        setOpen(false);
        setState({
          card_declined: false,
          error: false,
          input_error: false,
          no_card: false,
          other: false,
        });
        setOtherValue("");
        enqueueSnackbar({
          variant: "info",
          message:
            "We have recieved your feedback and we will get back to you as soon as possible!",
        });
      },
    });
  }
  const { card_declined, error, input_error, no_card, other } = state;
  return (
    <>
      <Typography
        sx={{ cursor: "pointer", color: "green" }}
        fontWeight={500}
        fontSize={12}
        onClick={() => {
          setOpen(true);
          setOtherToggled(false);
        }}
      >
        Have payment issues? Click here! We are here to help.{" "}
      </Typography>
      <Modal
        open={open}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        sx={{
          "& .MuiModal-backdrop": {
            backgroundColor: "rgba(0, 0, 0, 0.5);",
          },
          width: "calc(390px - 5vw)",
          margin: "auto",
          mt: "10rem",
        }}
      >
        <Fade in={open} sx={{ border: "none", outline: "none" }}>
          <MainCard
            title={
              <Stack
                direction="row"
                justifyContent="space-between"
                alignItems="center"
              >
                <Typography fontWeight={600}>Report an issue</Typography>
                <FaTimes
                  color="red"
                  fontSize={14}
                  style={{ cursor: "pointer" }}
                  onClick={() => setOpen(false)}
                />
              </Stack>
            }
            sx={{ width: "calc(390px - 5vw)" }}
          >
            {isSending ? (
              <CircularProgress />
            ) : (
              <>
                {" "}
                <FormGroup>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={no_card}
                        onChange={handleChange}
                        name="no_card"
                      />
                    }
                    label="I don't have credit/debit card"
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={card_declined}
                        onChange={handleChange}
                        name="card_declined"
                      />
                    }
                    label="Card declined"
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={error}
                        onChange={handleChange}
                        name="error"
                      />
                    }
                    label="Error message displayed"
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={input_error}
                        onChange={handleChange}
                        name="input_error"
                      />
                    }
                    label="Unable to enter card details"
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={other}
                        onChange={handleChange}
                        name="other"
                      />
                    }
                    label="Other"
                  />
                  {otherToggled && (
                    <TextField
                      size="small"
                      placeholder="please describe your issue"
                      value={otherValue}
                      onChange={(e) => setOtherValue(e.target.value)}
                    />
                  )}
                </FormGroup>
                <Button
                  fullWidth
                  variant="contained"
                  sx={{ mt: "1rem" }}
                  onClick={handlePaymentIssue}
                >
                  Submit
                </Button>
              </>
            )}
          </MainCard>
        </Fade>
      </Modal>
    </>
  );
}

export default PaymentFeedback;
