import {
  Alert,
  CircularProgress,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Stack,
  Typography,
} from "@mui/material";
import useGetEditedExam from "Api/hooks/useGetEditedExam";
import { useGetTask } from "Api/hooks/useGetTask";
import { usePostWriting } from "Api/hooks/usePostWriting";
import { Document, InfoCircle } from "iconsax-react";
import { useEffect, useRef, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import TransitionsModal from "sections/modal/TransitionsModal";
import Avatar from "../../@extended/Avatar";
import MainCard from "../../MainCard";
import IELTSTimer from "./IELTSTimer";
import TaskForm from "./SimulatorTaskForm";
import { enqueueSnackbar } from "notistack";
function createTasks(tasks) {
  const task_1 = tasks?.find((t) => t.task_type == 1);
  const task_2 = tasks?.find((t) => t.task_type == 2);
  return { task_1, task_2 };
}
function SimulatorForm() {
  const [searchParams] = useSearchParams();
  const exam_id = searchParams.get("examId");
  const { editedExam, isEditedExamLoading } = useGetEditedExam(exam_id);
  const exam_type = searchParams.get("exam_type") || editedExam?.type;
  const navigate = useNavigate();

  const formRef = useRef(null);
  const { task, isLoading } = useGetTask({
    exam_type,
    task_type: "",
    exam_id,
  });
  const [welcomeModalOpen, setWelcomeModalOpen] = useState(
    exam_id ? false : true
  );
  const [currentTask, setCurrentTask] = useState(1);
  const [isRunning, setIsRunning] = useState(false);
  const { postWriting, isPosting } = usePostWriting("final-submission");

  const { task_1, task_2 } = createTasks(task ? task : editedExam?.writings);
  const [examData, setExamData] = useState({
    task1_description: "",
    task1_answer: "",
    task2_description: "",
    task2_answer: "",
    exam_type: exam_id ? editedExam?.type : exam_type,
    ...(!editedExam && { task1_id: "" }),
    ...(!editedExam && { task2_id: "" }),
    service: "simulation",
    exam_duration: 0,
    status: "draft",
    task1_duration: 0,
    task2_duration: 0,
  });

  useEffect(() => {
    setExamData((prev) => ({
      ...prev,
      task1_description: task_1?.task_description,
      task2_description: task_2?.task_description,
      exam_type: exam_id ? editedExam?.type : exam_type,
      ...(!editedExam && { task1_id: task_1?.id }),
      ...(!editedExam && { task2_id: task_2?.id }),
      ...(editedExam && { exam_duration: editedExam?.exam_duration }),
      ...(editedExam && { task1_duration: task_1?.duration }),
      ...(editedExam && { task2_duration: task_2?.duration }),
      ...(editedExam && { id: editedExam?.id }),
    }));
  }, [task, editedExam]);
  function startExam() {
    setWelcomeModalOpen(false);
    setIsRunning(true);
  }
  function submitHandler(values) {
    const writingData = { ...examData };
    writingData.task2_answer = values.answer;
    if (!writingData.task1_answer || !writingData.task2_answer) {
      enqueueSnackbar({
        message:
          "You cannot submit a task with no answer, please try again and complete the task.",
        variant: "error",
      });
      navigate("/exam/simulator");
      return;
    }
    postWriting(writingData);
  }
  function nextTask(values) {
    setCurrentTask(2);
    setExamData((prev) => ({ ...prev, task1_answer: values.answer }));
  }

  function previousTask(values) {
    setCurrentTask(1);
    setExamData((prev) => ({ ...prev, task2_answer: values.answer }));
  }

  if (isLoading || isPosting || isEditedExamLoading)
    return <CircularProgress sx={{ margin: "auto" }} />;
  return (
    <>
      {exam_id ? (
        <Alert sx={{ mb: "1rem" }} icon={<InfoCircle />}>
          IELTS Exam simulations are not editable, you can do a quick review and
          the procced to checkout.
        </Alert>
      ) : null}
      <MainCard sx={{ marginBottom: "1rem" }}>
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          spacing={5}
        >
          <List disablePadding>
            <ListItem sx={{ p: 0 }}>
              <ListItemAvatar>
                <Avatar color="info" variant="rounded">
                  <Document variant="Bold" />
                </Avatar>
              </ListItemAvatar>
              <ListItemText
                sx={{ my: 0 }}
                primary={
                  <Typography variant="subtitle1">
                    Task {currentTask}
                  </Typography>
                }
                secondary={
                  <>
                    <Typography variant="subtitle2">
                      You should spend about {currentTask == 1 ? "20" : "40"}
                      &nbsp; minutes on this task. Write at least{" "}
                      {currentTask == 1 ? "150" : "250"} words.
                    </Typography>
                    <p>
                      you can toggle between task 1 and task 2 and complete them
                      the way you want, using the "previous task" and "next
                      task" buttons below the exam form.
                    </p>
                  </>
                }
              />
            </ListItem>
          </List>
          <ListItemAvatar>
            <IELTSTimer
              formValues={formRef.current?.values}
              isEdit={exam_id ? true : false}
              initialTime={exam_id ? 3600 - editedExam.exam_duration : 3600}
              // key={currentTask}
              isRunning={isRunning}
              submitHandler={submitHandler}
              setIsRunning={setIsRunning}
              nextQuestion={nextTask}
              currentTask={currentTask}
              setTaskDuration={setExamData}
            />
          </ListItemAvatar>
        </Stack>
      </MainCard>
      <Stack
        key={currentTask}
        sx={{ filter: `${welcomeModalOpen ? "blur(20px)" : ""}` }}
      >
        <>
          <TransitionsModal
            open={welcomeModalOpen}
            setOpen={setWelcomeModalOpen}
            startExam={startExam}
          />
        </>

        <TaskForm
          formRef={formRef}
          previousTask={previousTask}
          examData={examData}
          isEdit={exam_id ? true : false}
          task={currentTask == 1 ? task_1 : task_2}
          onSubmit={currentTask == 1 ? nextTask : submitHandler}
          currentTask={currentTask}
          welcomeModalOpen={welcomeModalOpen}
          setCurrentTask={setCurrentTask}
        />
      </Stack>
    </>
  );
}

export default SimulatorForm;
