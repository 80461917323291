import { Card, Stack } from "@mui/material";
import Avatar from "components/@extended/Avatar";
import React from "react";
import { Link } from "react-router-dom";

function DashboardTile({ href, title, icon }) {
  return (
    <Link style={{ textDecoration: "none" }} to={href}>
      <Card sx={{ margin:'0.5rem', height: "70px" }}>
        <Stack  gap="0.5rem" sx={{height:'100%'}} justifyContent="center" alignItems="center" direction="row">
          {icon}
          <p style={{ margin: 0, padding: 0 }}>{title}</p>
        </Stack>
      </Card>
    </Link>
  );
}

export default DashboardTile;
