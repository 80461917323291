import { Alert, Button, Divider, Stack, Typography } from "@mui/material";
import MainCard from "components/MainCard";
import React from "react";
import { MdMoney } from "react-icons/md";
import { Link } from "react-router-dom";
import { IoBagCheckOutline } from "react-icons/io5";
function PayByCreditForm({ payByCredit }) {
  return (
    <MainCard sx={{ maxWidth: "600px", margin: "auto" }}>
      <Stack spacing="1rem" alignItems="center">
        <IoBagCheckOutline color="#3ae470" fontSize="75px" />
        <Typography variant="h2" color="#3ae470">
          You have enough credits!
        </Typography>
        {/* <Alert>
          "Fantastic news! You have enough writings to instantly complete your
          order on our IELTS services. No need to fuss with card details – your
          writings are your currency here. Enjoy smooth, hassle-free
          transactions with just a tap. Let's dive into your study sessions with
          confidence and ease! or you could pay later if you'd like!"
        </Alert> */}
      </Stack>
      <Divider sx={{ margin: "2rem 1rem" }} />
      <Stack spacing="1rem">
        <Button
          variant="contained"
          onClick={payByCredit}
          sx={{ textTransform: "none",color:'black' }}
        >
          Get my report now
        </Button>
        <Button component={Link} to="/drafts" sx={{ textTransform: "none" }}>
          Cancel and save in drafts
        </Button>
      </Stack>
    </MainCard>
  );
}

export default PayByCreditForm;
