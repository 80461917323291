import {
  Button,
  CircularProgress,
  Grid,
  Stack,
  Typography,
} from "@mui/material";
import useDashboard from "Api/hooks/useDashboard";
import DashboardImprovements from "components/Improvements/DashboardImprovements";
import DashboardCard from "components/dashboard/DashboardDataCard";
import DashboardTile from "components/dashboard/DashboardTile";
import OverallScoreChart from "components/report/OverallScoreChart";
import { ArrowDown, ArrowUp } from "iconsax-react";
import React, { useEffect, useState } from "react";
import { FaChartBar, FaChartLine, FaPaperPlane } from "react-icons/fa";
import {
  GrDocumentNotes,
  GrDocumentPerformance,
  GrScorecard,
} from "react-icons/gr";
import { HiClipboardDocumentList } from "react-icons/hi2";
import { Link } from "react-router-dom";
import {
  FcQuestions,
  FcBullish,
  FcDiploma1,
  FcBarChart,
  FcRatings,
  FcSurvey,
  FcDocument,
} from "react-icons/fc";
import MainCard from "components/MainCard";
import { MdEditDocument } from "react-icons/md";
const features = [
  {
    title: "My reports",
    href: "/my-reports",
    icon: <FcBarChart fontSize={24} />,
  },
  {
    title: "My drafts",
    href: "/my-reports",
    icon: <FcDocument fontSize={24} />,
  },
  {
    title: "Spelling practice",
    href: "/spelling",
    icon: <FcSurvey fontSize={24} />,
  },
  {
    title: "IELTS practice",
    href: "/exam/practice",
    icon: <FcQuestions fontSize={24} />,
  },
  {
    title: "IELTS scoring",
    href: "/exam/scoring",
    icon: <FcRatings fontSize={24} />,
  },
  {
    title: "IELTS simulator",
    href: "/exam/simulator",
    icon: <FcDiploma1 fontSize={24} />,
  },
  // {
  //   title: "Improvements",
  //   href: "/improvements",
  //   icon: <FcBullish fontSize={24} />,
  // },
];
function Dashboard() {
  const [showQuickAccess, setShowQuickAccess] = useState(true);
  const [taskType, setTasktype] = useState(1);
  const [examType, setExamType] = useState("academic");
  const { dashboard, isLoading, refetch, isRefetching } = useDashboard(
    taskType,
    examType
  );
  useEffect(() => {
    refetch();
  }, [taskType, examType]);
  if (isLoading) return <CircularProgress sx={{ margin: "auto" }} />;

  return (
    <>
      <Stack>
        <Button
          onClick={() => setShowQuickAccess((prev) => !prev)}
          variant="outlined"
          sx={{ maxWidth: "250px", ml: 1 }}
        >
          {showQuickAccess
            ? "Hide quick access menu"
            : "Show quick access menu"}
        </Button>
        {showQuickAccess && (
          <Grid container>
            {features.map((feature) => {
              return (
                <Grid item xs={6} md={3} lg={2}>
                  <DashboardTile
                    title={feature.title}
                    href={feature.href}
                    icon={feature.icon}
                  />
                </Grid>
              );
            })}
          </Grid>
        )}
      </Stack>
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        padding={1}
      >
        <Typography variant="h5">Exam stats overview</Typography>
        <Button
          component={Link}
          to="/exam"
          variant="contained"
          sx={{ color: "black" }}
        >
          Take an exam
        </Button>
      </Stack>
      <Stack sx={{ marginTop: "1rem", padding: 1 }}>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={4} md={4}>
            <DashboardCard
              color="info"
              title="Total Exams"
              amount={dashboard?.exam_count}
              icon={<HiClipboardDocumentList />}
            />
          </Grid>
          <Grid item xs={12} sm={4} md={4}>
            <DashboardCard
              color="success"
              title="Highest Score"
              amount={Number(dashboard?.max_score).toFixed(1)}
              icon={<ArrowUp style={{ transform: "rotate(45deg)" }} />}
            />
          </Grid>
          <Grid item xs={12} sm={4} md={4}>
            <DashboardCard
              color="warning"
              title="Lowest Score"
              amount={Number(dashboard?.min_score).toFixed(1)}
              icon={<ArrowDown style={{ transform: "rotate(45deg)" }} />}
            />
          </Grid>
          {dashboard.exam_count ? (
            <Grid item xs={12}>
              {!isRefetching && (
                <OverallScoreChart
                  taskType={taskType}
                  setExamType={setExamType}
                  examType={examType}
                  setTaskType={setTasktype}
                  scores={dashboard?.overall_scores}
                />
              )}
              {isRefetching && (
                <MainCard>
                  <Stack
                    sx={{ width: "full", height: "400px" }}
                    alignItems="center"
                    justifyContent="center"
                  >
                    <CircularProgress sx={{ margin: "auto" }} />
                  </Stack>
                </MainCard>
              )}
            </Grid>
          ) : null}
          {/* <Grid item xs={12} md={6}>
            <DashboardImprovements improvements={dashboard?.improvements} />
          </Grid> */}
        </Grid>
      </Stack>
    </>
  );
}

export default Dashboard;
