import { useQuery } from "@tanstack/react-query";
import { getCountryApi } from "Api/providers/userProviders";

export default function useGetCountry() {
  const { data: country, isLoading } = useQuery({
    queryFn: getCountryApi,
    queryKey: ["country"],
  });
  return { country };
}
