import React from "react";
import MainCard from "../MainCard";
import {
  Alert,
  Button,
  CircularProgress,
  Divider,
  Stack,
  Typography,
} from "@mui/material";
import useUser from "Api/hooks/useUser";
import { Link } from "react-router-dom";
import useGetPackage from "Api/hooks/useGetPackage";
import { HiCheckCircle } from "react-icons/hi2";

function TransactionComplete({ planId =null}) {
  const { user } = useUser();
  const customerEmail = user?.user?.email;
  const { writingPackage, isLoading } = useGetPackage(planId);
  if (isLoading) return <CircularProgress sx={{ margin: "auto" }} />;
  return (
    <MainCard sx={{ maxWidth: "600px", margin: "auto" }}>
      <Stack spacing="1rem" alignItems="center">
        <HiCheckCircle color="#3ae470" fontSize="75px" />
        <Typography variant="h2" color="#3ae470">
          Payment Successful!
        </Typography>
        {planId ?         <Typography variant="subtitle1" color="GrayText">
          {writingPackage.description}
        </Typography> : null}
        <Alert>
          We recieved your order!. An email containing your payment recipt is
          will be sent to <strong>{customerEmail}</strong> and your purchased
          writings will be added to your account in no time!
        </Alert>
      </Stack>
      <Divider sx={{ margin: "2rem 1rem" }} />
      <Stack>
        <Button
          variant="contained"
          sx={{ color: "black" }}
          component={Link}
          to="/"
        >
          Home
        </Button>
      </Stack>
    </MainCard>
  );
}

export default TransactionComplete;
