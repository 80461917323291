import {
  Button,
  CircularProgress,
  Grid,
  Stack,
  TextField,
  Typography,
  FormHelperText,
} from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import MainCard from "../../MainCard";
import { Formik } from "formik";
import { ArrowLeft } from "iconsax-react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { usePostWriting } from "Api/hooks/usePostWriting";
import { countwords } from "utils/contwords";
import SingleFileUpload from "../../third-party/dropzone/SingleFile";
import { useAutoSave } from "Api/hooks/useAutoSave";
import ExitModal from "../../ExitModal";
import useGetEditedExam from "Api/hooks/useGetEditedExam";
import { createScoringData, validateScoringExam } from "../examUtils";
function ScoringForm() {
  const [searchParams] = useSearchParams();
  const formRef = useRef(null);
  const examId = searchParams.get("examId");
  const { editedExam, isEditedExamLoading } = useGetEditedExam(examId);

  const [submitType, setSubmitType] = useState();

  const { postWriting, isPosting } = usePostWriting(submitType);
  const [autoSaveId, setAutoSaveId] = useState();
  const [exitModal, setExitModal] = useState(false);
  const { autoSave, lastSaved } = useAutoSave();

  const navigate = useNavigate();
  const exam_type = editedExam?.type || searchParams.get("exam_type");
  const task_type =
    editedExam?.writings[0]?.task_type || searchParams.get("task_type");

  const hasFile = exam_type == "academic" && task_type == 1;

  useEffect(() => {
    if (!editedExam) return;
    setAutoSaveId(editedExam?.id);
  }, [editedExam]);
  function handleQuit() {
    if (
      examId &&
      editedExam.writings[0]?.task_description ==
        formRef.current.values.description &&
      editedExam?.writings[0]?.task_answer === formRef.current.values.answer
    ) {
      navigate("/drafts");
      return;
    }
    if (
      formRef.current.values.answer == "" &&
      formRef.current.values.description == ""
    ) {
      navigate("/exam");
      return;
    }
    setExitModal(true);
  }

  function submitHandler(values) {
    const examData = createScoringData(
      values.description,
      values.answer,
      task_type,
      autoSaveId,
      exam_type,
      hasFile && values?.files ? values?.files[0] : null,
      hasFile
    );
    const formData = new FormData();
    Object.keys(examData).map((key) => {
      if (examData[key] != null) {
        formData.append(key, examData[key]);
      }
    });
    postWriting(formData);
  }

  function autoSaveHandler() {
    if (formRef?.current?.values?.answer == "") return;
    if (formRef?.current?.values?.description == "") return;

    const examData = createScoringData(
      formRef?.current?.values.description,
      formRef?.current?.values.answer,
      task_type,
      autoSaveId,
      exam_type,
      hasFile && formRef?.current?.values?.files
        ? formRef?.current?.values?.files[0]
        : null,
      hasFile
    );
    const formData = new FormData();
    Object.keys(examData).map((key) => {
      if (examData[key] != null) {
        formData.append(key, examData[key]);
      }
    });
    autoSave(formData, { onSuccess: (data) => setAutoSaveId(data.id) });
  }

  useEffect(() => {
    const interval = setInterval(autoSaveHandler, 20000);
    return () => clearInterval(interval);
  }, [formRef.current]);

  if (isEditedExamLoading || isPosting)
    return <CircularProgress sx={{ margin: "auto" }} />;
  return (
    <>
      <Formik
        innerRef={formRef}
        enableReinitialize={true}
        initialValues={{
          description: editedExam?.writings[0]?.task_description || "",
          answer: editedExam?.writings[0]?.task_answer || "",
          file: null,
        }}
        validate={(values) => validateScoringExam(values, submitType)}
        onSubmit={(values) => submitHandler(values)}
      >
        {({
          errors,
          handleChange,
          handleSubmit,
          touched,
          values,
          setFieldValue,
        }) => (
          <>
            <form onSubmit={handleSubmit} noValidate>
              <Stack>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={6}>
                    <MainCard
                      title="Task Description"
                      subheader="Enter your task description here"
                      sx={{
                        height: "500px",
                        maxHeight: "500px",
                        overflow: "scroll",
                      }}
                    >
                      <TextField
                        sx={{
                          "& .Mui-disabled": {
                            opacity: 100,
                            color: "inherit",
                            WebkitTextFillColor: "unset",
                          },
                        }}
                        id="description"
                        multiline
                        rows={15}
                        name="description"
                        fullWidth
                        value={values.description}
                        onChange={handleChange}
                      />
                      {touched.description && errors.description && (
                        <FormHelperText
                          error
                          id="standard-weight-helper-text-description"
                        >
                          {errors.description}
                        </FormHelperText>
                      )}
                      {editedExam?.writings[0]?.task_type == "1" && (
                        <img
                          src={editedExam?.writings[0]?.file}
                          style={{
                            objectFit: "cover",
                            width: "100%",
                            marginTop: "1rem",
                          }}
                        />
                      )}
                    </MainCard>
                    {!examId && hasFile && (
                      <MainCard
                        sx={{ marginTop: "1rem" }}
                        title="Task Image"
                        subheader="Please upload the task image here"
                      >
                        <SingleFileUpload
                          setFieldValue={setFieldValue}
                          file={values.files}
                        />
                      </MainCard>
                    )}
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <MainCard
                      sx={{ height: "500px" }}
                      title={
                        <Stack direction="row" justifyContent="space-between">
                          <Typography variant="subtitle1">
                            Task answer
                          </Typography>
                          <Typography>
                            {countwords(values.answer || "")} words
                          </Typography>
                        </Stack>
                      }
                      subheader="Enter your task answer here"
                    >
                      <TextField
                        sx={{
                          "& .Mui-disabled": {
                            opacity: 100,
                            color: "inherit",
                            WebkitTextFillColor: "unset",
                          },
                        }}
                        id="answer"
                        multiline
                        rows={15}
                        name="answer"
                        fullWidth
                        value={values.answer}
                        onChange={handleChange}
                      />
                      {touched.answer && errors.answer && (
                        <FormHelperText
                          error
                          id="standard-weight-helper-text-answer"
                        >
                          {errors.answer}
                        </FormHelperText>
                      )}
                      <Typography variant="caption" color="GrayText">
                        Last saved {lastSaved || "just now"}
                      </Typography>
                    </MainCard>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                      marginBottom: "1rem",
                    }}
                  >
                    <Button
                      variant="contained"
                      color="error"
                      onClick={() => handleQuit()}
                    >
                      <ArrowLeft />
                    </Button>
                    <Stack direction="row" gap="1rem">
                      <Button
                        size="small"
                        variant="outlined"
                        type="submit"
                        onClick={() => setSubmitType("draft")}
                      >
                        Save as draft
                      </Button>
                      <Button
                        size="small"
                        variant="contained"
                        type="submit"
                        onClick={() => setSubmitType("final-submission")}
                      >
                        Score my writing
                      </Button>
                    </Stack>
                  </Grid>
                </Grid>
              </Stack>
            </form>
            <ExitModal
              open={exitModal}
              setOpen={setExitModal}
              save={() => autoSaveHandler()}
            />
          </>
        )}
      </Formik>
    </>
  );
}

export default ScoringForm;
