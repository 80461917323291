import {
  CircularProgress,
  Grid,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Stack,
  Typography,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import MainCard from "components/MainCard";
import React, { useEffect, useRef, useState } from "react";
import { useParams } from "react-router";
import UserPersonalData from "components/report/ReportSummary";
import EcommerceRadial from "components/report/ScoreChart";
import useUser from "Api/hooks/useUser";
import Avatar from "components/@extended/Avatar";
import { Calendar, Document, User } from "iconsax-react";
import moment from "moment";
import axios from "../../utils/axios";
import ReportSection from "components/report/ReportSection";
import ReportHeaderData from "components/report/ReportHeader";
import ReportCard from "components/cards/statistics/ReportCard";
import FrequentlyUsedWords from "./FrequentlyUsedWords";
import GrammerErrors from "./GrammerErrors";
import SpellingErrors from "./SpellingErrors";
import { logEvent } from "utils/analytics";
import ReportStatsData from "components/report/ReportStatsData";
const titles = {
  task_achievement: "Task Achievement",
  coherence: "Coherence and Cohesion",
  lexical: "Lexical Resources",
  grammatical: "Grammatical Range and  Accuracy",
};
function Result() {
  const params = useParams();
  const [exam, setExam] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [selectedTask, setSelectedTask] = useState(2);
  const [selectedWriting, setSelectedWriting] = useState(null);

  const [eventLogged, setEventLogged] = useState(false);
  useEffect(() => {
    console.log("mounted");
    axios
      .get(`/exams/${params?.id}`)
      .then(({ data }) => {
        setExam(data.data);
        setIsLoading(false);
        setSelectedWriting(data?.data?.writings[0]);
        setSelectedTask(Number(data?.data?.writings[0]?.task_type));
        logEvent("get_report", {
          service_title: data.data.service,
          writing_count: data.data.writing_count,
          exam_id: data.data.id,
          user_name: data.data.writings[0].user_name,
        });
      })
      .catch((error) => {
        setIsLoading(false);
      });
  }, []);
  useEffect(() => {
    const selectedWriting = exam?.writings?.find(
      (writing) => Number(writing?.task_type) == selectedTask
    );
    setSelectedWriting(selectedWriting);
  }, [selectedTask]);
  function getStatReportColor(score) {
    const color = score > 4 ? "#4ade80" : "#f43f5e";
    return color;
  }

  // useEffect(() => {
  //   if (eventLoggedRef.current) return; // Skip if the event has been logged
  //   console.log(eventLoggedRef.current)
  //   if (!exam?.service || !exam?.writing_count || !exam?.id || !selectedWriting?.user_name) return;

  //   console.log(exam);
  //   logEvent('get_report', {
  //     service_title: exam.service,
  //     writing_count: exam.writing_count,
  //     exam_id: exam.id,
  //     user_name: selectedWriting.user_name
  //   });

  //   eventLoggedRef.current = true; // Mark the event as logged
  // }, [exam]);
  const { user, isLoading: userLoading } = useUser();
  const color = exam?.score > 4 ? "#4ade80" : "#f43f5e";
  if (isLoading || userLoading || selectedWriting == null)
    return <CircularProgress sx={{ margin: "auto" }} />;
  return (
    <Stack spacing={3}>
      <Typography variant="h2" sx={{ fontWeight: 700 }}>
        Writing Result
      </Typography>
      <Stack>
        <Grid container rowSpacing={1.5} columnSpacing={2.75}>
          <Grid item xs={12} sm={6} lg={3}>
            <MainCard style={{ maxHeight: "95px" }}>
              <List disablePadding>
                <ListItem sx={{ p: 0 }}>
                  <ListItemAvatar>
                    <Avatar color="info" variant="rounded">
                      <User variant="Bold" />
                    </Avatar>
                  </ListItemAvatar>
                  <ListItemText
                    sx={{ my: 0 }}
                    primary={<Typography>{user?.user?.name}</Typography>}
                    secondary={
                      <Typography variant="subtitle1">
                        {exam?.exam?.toUpperCase()}
                      </Typography>
                    }
                  />
                </ListItem>
              </List>
            </MainCard>
          </Grid>
          <Grid item xs={12} sm={6} lg={3}>
            <MainCard style={{ maxHeight: "95px" }}>
              <List disablePadding>
                <ListItem sx={{ p: 0 }}>
                  <ListItemAvatar>
                    <Avatar color="warning" variant="rounded">
                      <Document variant="Bold" />
                    </Avatar>
                  </ListItemAvatar>
                  <ListItemText
                    sx={{ my: 0 }}
                    primary={<Typography>{exam?.service_title}</Typography>}
                    secondary={
                      <Typography variant="subtitle1">
                        {exam?.type?.toUpperCase()}
                      </Typography>
                    }
                  />
                </ListItem>
              </List>
            </MainCard>
          </Grid>
          <Grid item xs={12} sm={6} lg={3}>
            <MainCard style={{ maxHeight: "95px" }}>
              <List disablePadding>
                <ListItem sx={{ p: 0 }}>
                  <ListItemAvatar>
                    <Avatar color="success" variant="rounded">
                      <Calendar variant="Bold" />
                    </Avatar>
                  </ListItemAvatar>
                  <ListItemText
                    sx={{ my: 0 }}
                    primary={<Typography>Submitted At</Typography>}
                    secondary={
                      <Typography variant="subtitle1">
                        {moment(exam?.created_at).format("MMMM Do YYYY")}
                      </Typography>
                    }
                  />
                </ListItem>
              </List>
            </MainCard>
          </Grid>
          <Grid item xs={12} sm={6} lg={3}>
            <MainCard
              content={false}
              style={{ maxHeight: "95px", display: "flex" }}
            >
              <Stack
                direction="row"
                alignItems="center"
                spacing={2}
                sx={{ px: 2 }}
              >
                <Stack sx={{ width: 120, marginTop: "1rem" }}>
                  <EcommerceRadial
                    color={color}
                    value={Number(exam?.score)?.toFixed(1)}
                  />
                </Stack>
                <Stack>
                  <Typography>Overall Score</Typography>
                  <Typography variant="subtitle1">
                    {Number(exam?.score)?.toFixed(1)} out of 9.0
                  </Typography>
                </Stack>
              </Stack>
            </MainCard>
          </Grid>
        </Grid>
      </Stack>
      <Stack>
        <Grid
          container
          rowSpacing={1.5}
          columnSpacing={2.75}
          alignItems="center"
          justifyContent="center"
        >
          <Grid item xs={12}>
            <ReportHeaderData
              exam={exam}
              writingResult={selectedWriting}
              selectedTask={selectedTask}
              examWritings={exam?.writings}
              setSelectedTask={setSelectedTask}
            />
          </Grid>
          <Grid item xs={12}>
            <ReportStatsData writingResult={selectedWriting} />
          </Grid>
          <Grid item xs={12}>
            <UserPersonalData
              selectedTask={selectedTask}
              writing={selectedWriting}
              examWritings={exam?.writings}
              setSelectedTask={setSelectedTask}
            />
          </Grid>
          {selectedWriting.key && selectedWriting.key == "v2" ? (
            <Grid container item xs={12} spacing={1}>
              <Grid item xs={6} sm={3}>
                <ReportCard
                  primary={`${selectedWriting?.result?.clarity_score?.toFixed(
                    1
                  )} out of 9.0`}
                  textColor={getStatReportColor(
                    selectedWriting?.result?.clarity_score
                  )}
                  secondary="Clarity Score"
                  iconColor="primary"
                  description="This measures how clearly and effectively your ideas are communicated. A high clarity score indicates that your writing is easy to understand and free from ambiguity."
                />
              </Grid>
              <Grid item xs={6} sm={3}>
                <ReportCard
                  primary={`${selectedWriting?.result?.example_score?.toFixed(
                    1
                  )} out of 9.0`}
                  textColor={getStatReportColor(
                    selectedWriting?.result?.example_score
                  )}
                  secondary="Example Score"
                  iconColor="info"
                  description="This assesses the relevance and quality of examples you use to support your arguments. A high example score means your examples are pertinent, well-explained, and enhance your points."
                />
              </Grid>
              <Grid item xs={6} sm={3}>
                <ReportCard
                  primary={`${selectedWriting?.result?.readability_score?.toFixed(
                    1
                  )} out of 9.0`}
                  textColor={getStatReportColor(
                    selectedWriting?.result?.readability_score
                  )}
                  secondary="Readability Score"
                  iconColor="success"
                  description="This evaluates how easily your text can be read and understood. Factors influencing readability include sentence length, word choice, and overall flow. A high readability score means your writing is smooth and accessible."
                />
              </Grid>
              <Grid item xs={6} sm={3}>
                <ReportCard
                  primary={`${selectedWriting?.result?.repetition_score?.toFixed(
                    1
                  )} out of 9.0`}
                  textColor={getStatReportColor(
                    selectedWriting?.result?.repetition_score
                  )}
                  secondary="Repetitaion Score"
                  iconColor="secondary"
                  description="This measures the degree of unnecessary repetition in your writing. A low repetition score indicates that your writing is concise, varied, and free from redundant phrases or ideas.
                  "
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <FrequentlyUsedWords
                  words={selectedWriting?.result?.frequently_words}
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <GrammerErrors
                  grammerErrors={selectedWriting?.result?.grammer_errors}
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <SpellingErrors
                  spellingErrors={selectedWriting?.result?.spelling_errors}
                />
              </Grid>
            </Grid>
          ) : null}
          {Object.keys(titles).map((key, index) => (
            <>
              <Grid item xs={12} key={index}>
                <ReportSection
                  key={key}
                  writing={selectedWriting}
                  result_key={key}
                  title={titles[key]}
                />
              </Grid>
            </>
          ))}
        </Grid>
      </Stack>
    </Stack>
  );
}

export default Result;
