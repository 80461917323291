import { Button, Grid, Modal, Stack, Typography } from "@mui/material";
import useUser from "Api/hooks/useUser";
import React, { useEffect, useState } from "react";
import verifyImage from "../../../assets/images/verifyImage.png";
import ConfirmEmailBox from "components/ConfirmEmailBox";
import MainCard from "components/MainCard";
import congrats from "../../../assets/images/congrats.png";
import { Link, useSearchParams } from "react-router-dom";
import useVerifyEmail from "Api/hooks/useVerifyEmail";
import { enqueueSnackbar } from "notistack";
import { logEvent} from "utils/analytics";
function EmailConfirmation() {
  const [isVerified, setIsVerified] = useState(false);
  const [code, setCode] = useState("");
  const { user,refetch } = useUser();
  const [searchParams] = useSearchParams()
  const { isResending, isVerifying, resendCode, verifyEmail } =
    useVerifyEmail();
  function verify() {
    if (code == "") {
      enqueueSnackbar({
        message: "Make sure to write in the code",
        variant: "warning",
      });
      return;
    }
    verifyEmail(code, {
      onSuccess: () => {
        setIsVerified(true);
        logEvent("email_verified", {
          email: user?.user?.email,
        });
        refetch()
      },
      onError: (data) => {
        enqueueSnackbar({
          message: data?.message,
          variant: "error",
        });
        setCode("");
      },
    });
  }
  function resendEmailCode() {
    resendCode();
  }
  useEffect(()=>{
    if(searchParams.has('resend')){
      resendCode()
    }
  },[searchParams])
  return (
    <Stack>
      <Grid container sx={{ height: "100svh" }}>
        <Grid item xs={12} md={8}>
          <Stack
            sx={{
              display: "flex",
              justifyContent: "center",
              padding: "1rem",
              height: "100%",
              alignItems: "center",
            }}
          >
            <ConfirmEmailBox
              resend={resendEmailCode}
              isResending={isResending}
              isVerifying={isVerifying}
              isVerified={isVerified}
              setIsVerified={setIsVerified}
              verify={verify}
              code={code}
              setCode={setCode}
            />
          </Stack>
        </Grid>
        <Grid
          item
          sx={{
            display: { xs: "none", md: "flex" },
            alignItems: "center",
            justifyContent: "center",
          }}
          xs={4}
        >
          <img
            src={verifyImage}
            style={{ objectFit: "cover", width: "400px" }}
          />
        </Grid>
      </Grid>
      {isVerified && (
        <div>
          <Modal
            open={isVerified}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            sx={{
              "& .MuiModal-backdrop": {
                backgroundColor: "rgba(0, 0, 0, 0.5);",
              },
            }}
          >
            <Stack
              alignItems="center"
              justifyContent="center"
              sx={{ height: "100svh" }}
            >
              <MainCard
                sx={{ minWidth: "300px", maxWidth: "450px", margin: "0 1rem" }}
              >
                <Stack alignItems="center" gap="1rem">
                  <img src={congrats} width={200} height={200} />
                  <Typography variant="h3">Congrats!</Typography>
                  <Typography variant="subtitle1">
                    Your account has been verified successfuly and you have been
                    granted a free writing credit that you can use in our
                    platform!
                  </Typography>
                  <Button variant="contained" LinkComponent={Link} to="/">
                    Continue
                  </Button>
                </Stack>
              </MainCard>
            </Stack>
          </Modal>
        </div>
      )}
    </Stack>
  );
}

export default EmailConfirmation;
