import React, { useState, useEffect } from "react";
import { Button } from "@mui/material";
import "./consent.css";
import { getConsentCookie, setConsentCookie } from "./cookies";
const ConsentCookies = () => {
  const [isVisible, setIsVisible] = useState(false);
  const [isMounted, setIsMounted] = useState(false);

  useEffect(() => {
    if (window.location.pathname.includes("print")) {
      return;
    }
    console.log(getConsentCookie());
    if (!getConsentCookie()) {
      window.dataLayer = window?.dataLayer || [];
      function gtag() {
        window.dataLayer.push(arguments);
      }
      gtag("consent", "default", {
        ad_user_data: "denied",
        ad_personalization: "denied",
        ad_storage: "denied",
        analytics_storage: "denied",
      });
      setIsMounted(true);
      setTimeout(() => {
        setIsVisible(true);
      }, 10);
    }
  }, []);
  useEffect(() => {
    if (getConsentCookie()) {
      window.dataLayer = window.dataLayer || [];
      function gtag() {
        window.dataLayer?.push(arguments);
      }
      gtag("consent", "update", {
        ad_user_data: "granted",
        ad_personalization: "granted",
        ad_storage: "granted",
        analytics_storage: "granted",
      });
    }
  }, []);
  const handleAccept = () => {
    setIsVisible(false);
    setTimeout(() => {
      setIsMounted(false);
      setConsentCookie();
    }, 300);
    window.dataLayer = window?.dataLayer || [];
    function gtag() {
      window.dataLayer.push(arguments);
    }
    gtag("consent", "update", {
      ad_user_data: "granted",
      ad_personalization: "granted",
      ad_storage: "granted",
      analytics_storage: "granted",
    });
  };
  const handleDecline = () => {
    setIsVisible(false);
    setTimeout(() => {
      setIsMounted(false);
    }, 300);
  };

  if (!isMounted) {
    return null;
  }

  return (
    <div
      className={`wrapper ${isVisible ? "translate-y-0" : "translate-y-full"}`}
    >
      <div className="container">
        <div className="space-y-2">
          <h3>We Use Cookies to Make Your WritingChex Experience Tastier</h3>
          <p>
            At WritingChex, we want your experience to be smooth and satisfying,
            just like a delicious lunch! To achieve this, we use cookies – small
            files placed on your device – to help our website function and
            understand how you use it. By continuing to use WritingChex, you
            consent to our use of cookies.
          </p>
        </div>
        <div
          style={{
            display: "flex",
            width: "100%",
            justifyContent: "end",
            gap: "1rem",
          }}
        >
          <Button variant="outlined" onClick={handleDecline}>
            Decline
          </Button>
          <Button onClick={handleAccept} variant="contained">
            Accept
          </Button>
        </div>
      </div>
    </div>
  );
};

export default ConsentCookies;
