import { useMutation, useQuery } from "@tanstack/react-query";
import { getProfieApi, setProfielApi } from "Api/providers/userProviders";

export default function useProfile() {
  const { mutateAsync: updateProfile, isPending: isUpdating } = useMutation({
    mutationFn: setProfielApi,
  });
  const { data: profile, isLoading: isProfileLoading } = useQuery({
    queryKey: ["profile"],
    queryFn: getProfieApi,
  });

  return {
    profile,
    isProfileLoading,
    updateProfile,
    isUpdating,
  };
}
