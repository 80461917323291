// import {
//   Button,
//   CardContent,
//   Divider,
//   Fade,
//   Modal,
//   Stack,
//   Typography,
// } from "@mui/material";
// import { useEffect, useState } from "react";
// import { useNavigate } from "react-router-dom";
// import MainCard from "../../MainCard";

// const IELTSTimer = ({
//   initialMinutes,
//   initialSeconds,
//   isRunning,
//   setIsRunning,
//   submitHandler,
//   nextQuestion,
//   currentTask,
//   setTaskDuration
// }) => {
//   const [minutes, setMinutes] = useState(initialMinutes);
//   const [seconds, setSeconds] = useState(initialSeconds);
//   const navigate = useNavigate();
//   useEffect(() => {
//     setMinutes(initialMinutes);
//     setSeconds(initialSeconds);
//     setIsRunning(currentTask === 1 ? false : true);
//   }, [initialMinutes, initialSeconds]);
//   useEffect(() => {
//     let interval;
//     if (isRunning) {
//       interval = setInterval(() => {
//         if(currentTask == 1){
//           setTaskDuration(prev=>({...prev,task1_duration:prev.task1_duration+1}))
//         }else{
//           setTaskDuration(prev=>({...prev,task2_duration:prev.task2_duration+1}))
//         }
//         if (seconds > 0) {
//           setSeconds((prevSeconds) => prevSeconds - 1);
//         } else {
//           if (minutes > 0) {
//             setMinutes((prevMinutes) => prevMinutes - 1);
//             setSeconds(59);
//           } else {
//             clearInterval(interval);
//             setIsRunning(false);
//           }
//         }
//       }, 1000);
//     }

//     return () => clearInterval(interval);
//   }, [isRunning, minutes, seconds]);
//   const isTimeOver = minutes == 0 && seconds == 0 ? true : false;

//   const formatTime = (time) => (time < 10 ? `0${time}` : time);

//   return (
//     <div style={{ display: "flex", alignItems: "center", gap: "1rem" }}>
//       <h3 style={{ color: "red" }}>
//         {formatTime(minutes)}:{formatTime(seconds)}
//       </h3>
//       <Modal
//         open={isTimeOver}
//         aria-labelledby="modal-modal-title"
//         aria-describedby="modal-modal-description"
//         sx={{
//           "& .MuiModal-backdrop": {
//             backgroundColor: "rgba(0, 0, 0, 0.5);",
//           },
//         }}
//       >
//         <Fade in={isTimeOver} sx={{ border: "none", outline: "none" }}>
//           <MainCard
//             title="IELTS Simulator"
//             modal
//             darkTitle
//             content={false}
//             sx={{ backgroundColor: "red", outline: "none", border: "none" }}
//           >
//             <CardContent>
//               <Typography variant="subtitle1" id="modal-modal-description">
//                 we are sorry to inform you that your given time for this task is
//                 over,
//               </Typography>
//               <Typography variant="subtitle2">
//                 <p>
//                   would you like to give it another try or submit your task now?
//                 </p>
//               </Typography>
//             </CardContent>
//             <Divider />
//             <Stack
//               direction="row"
//               spacing={1}
//               justifyContent="flex-end"
//               sx={{ px: 2.5, py: 2 }}
//             >
//               <Button
//                 color="info"
//                 size="small"
//                 variant="outlined"
//                 onClick={() => navigate("/exam?simulator")}
//               >
//                 Try Again
//               </Button>
//               <Button
//                 variant="contained"
//                 size="small"
//                 onClick={currentTask == 1 ? nextQuestion : submitHandler}
//               >
//                 {currentTask == 1 ? "Next Task" : "Submit"}
//               </Button>
//             </Stack>
//           </MainCard>
//         </Fade>
//       </Modal>
//     </div>
//   );
// };

// export default IELTSTimer;
import {
  Button,
  CardContent,
  Divider,
  Fade,
  Modal,
  Stack,
  Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import MainCard from "../../MainCard";

const IELTSTimer = ({
  initialTime,
  isRunning,
  setIsRunning,
  submitHandler,
  nextQuestion,
  currentTask,
  setTaskDuration,
  isEdit,
  formValues,
}) => {
  const [timeLeft, setTimeLeft] = useState(initialTime);
  const navigate = useNavigate();

  // useEffect(() => {
  //   setTimeLeft(initialTime);
  //  }, [initialTime, currentTask, setIsRunning]);
  useEffect(() => {
    if (isEdit) return;
    let interval;
    if (isRunning) {
      interval = setInterval(() => {
        setTaskDuration((prev) => ({
          ...prev,
          exam_duration: prev.exam_duration + 1,
        }));
        if (currentTask == 1) {
          setTaskDuration((prev) => ({
            ...prev,
            task1_duration: prev.task1_duration + 1,
          }));
        } else if (currentTask == 2) {
          setTaskDuration((prev) => ({
            ...prev,
            task2_duration: prev.task2_duration + 1,
          }));
        }
        setTimeLeft((prevTimeLeft) => {
          if (prevTimeLeft > 0) {
            return prevTimeLeft - 1;
          } else {
            clearInterval(interval);
            setIsRunning(false);
            return 0;
          }
        });
      }, 1000);
    }

    return () => clearInterval(interval);
  }, [isRunning, setTaskDuration, setIsRunning, currentTask]);

  const isTimeOver = timeLeft <= 0;

  const formatTime = (time) => {
    const minutes = Math.floor(time / 60);
    const seconds = time % 60;
    return `${String(minutes).padStart(2, "0")}:${String(seconds).padStart(
      2,
      "0"
    )}`;
  };

  return (
    <div style={{ display: "flex", alignItems: "center", gap: "1rem" }}>
      <h3 style={{ color: isEdit ? "green" : "red" }}>
        {formatTime(timeLeft)}
      </h3>
      <Modal
        open={isTimeOver}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        sx={{
          "& .MuiModal-backdrop": {
            backgroundColor: "rgba(0, 0, 0, 0.5);",
          },
        }}
      >
        <Fade in={isTimeOver} sx={{ border: "none", outline: "none" }}>
          <MainCard
            title="IELTS Simulator"
            modal
            darkTitle
            content={false}
            sx={{ backgroundColor: "red", outline: "none", border: "none" }}
          >
            <CardContent>
              <Typography variant="subtitle1" id="modal-modal-description">
                We are sorry to inform you that your given time for the exam is
                over.
              </Typography>
              <Typography variant="subtitle2">
                <p>
                  Would you like to give it another try or submit your task now?
                </p>
              </Typography>
            </CardContent>
            <Divider />
            <Stack
              direction="row"
              spacing={1}
              justifyContent="flex-end"
              sx={{ px: 2.5, py: 2 }}
            >
              <Button
                color="info"
                size="small"
                variant="outlined"
                onClick={() => navigate("/exam/simulator")}
              >
                Try Again
              </Button>
              <Button
                variant="contained"
                size="small"
                onClick={() => submitHandler(formValues)}
              >
                Submit
              </Button>
            </Stack>
          </MainCard>
        </Fade>
      </Modal>
    </div>
  );
};

export default IELTSTimer;
