import { Fade, Modal, Stack, Typography } from "@mui/material";
import MainCard from "components/MainCard";
import React from "react";
import { FaTimes } from "react-icons/fa";

function TaskImage({ imgSrc, setPreviewImage, previewImage }) {
  return (
    <>
      <img
        src={imgSrc}
        style={{
          objectFit: "cover",
          width: "100%",
          marginTop: "1rem",
          cursor: "pointer",
        }}
        onClick={() => setPreviewImage(true)}
      />
      <p style={{opacity:'40%'}}>click on the image for a closer view</p>
      <Modal
        open={previewImage}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        sx={{
          "& .MuiModal-backdrop": {
            backgroundColor: "rgba(0, 0, 0, 0.5);",
          },
          maxWidth: "800px",
          margin:{xs:'4rem 1rem',sm:'auto'},
          marginTop: "2rem",
          maxHeight:"600px",
          overflow:'scroll'
        }}
      >
        <Fade in={previewImage} sx={{ border: "none", outline: "none" }}>
          <MainCard
            title={
              <Stack direction="row" width="100%" alignItems="center" justifyContent="space-between">
                <Typography>Task image</Typography>
                <FaTimes
                  color="red"
                  style={{ fontSize: "20px", cursor: "pointer" }}
                  onClick={() => setPreviewImage(false)}
                />
              </Stack>
            }
          >
            <img
              src={imgSrc}
              style={{
                objectFit: "cover",
                width: "100%",
                maxWidth: "800px",
                marginTop: "1rem",
                cursor: "pointer",
              }}
              onClick={() => setPreviewImage(false)}
            />
          </MainCard>
        </Fade>
      </Modal>
    </>
  );
}

export default TaskImage;
