// project-imports
import Routes from "routes";
import ThemeCustomization from "themes";

import Loader from "components/Loader";
import ScrollTop from "components/ScrollTop";
import Snackbar from "components/@extended/Snackbar";
import Notistack from "components/third-party/Notistack";

// auth-provider
import { JWTProvider as AuthProvider } from "contexts/JWTContext";
import useUser from "Api/hooks/useUser";
import AppContextProvider from "contexts/AppContext";

import "./pages/main.css";
import { useLocation } from "react-router";
import { useEffect, useState } from "react";
import VerifyEmailModal from "components/VerifyEmailModal";
import CompleteProfileModal from "components/CompleteProfileModal";
import ConsentCookies from "utils/ConsentCookies";
const App = () => {
  const { user, isLoading } = useUser();
  const [verifyModalOpen, setVerifyModalOpen] = useState(false);
  // const [completProfileOpen, setCompleteProfileOpen] = useState(false);
  const closeModal = () => setVerifyModalOpen(false);
  // const closeCompleteProfile = () => setCompleteProfileOpen(false);
  const [locationChangeCount, setLocationChangeCount] = useState(0);
  const location = useLocation();
  useEffect(() => {
    if (
      location.pathname == "/email-confirmation" ||
      location.pathname == "/login" ||
      location.pathname == "/register"
    )
      return;
    if (user?.user?.email_is_confirmed) return;
    setLocationChangeCount((prevCount) => {
      const newCount = prevCount + 1;

      if (newCount % 4 === 0) {
        setVerifyModalOpen(true);
      }
      return newCount;
    });
  }, [location]);
  // useEffect(() => {
  //   if (
  //     location.pathname == "/email-confirmation" ||
  //     location.pathname == "/login" ||
  //     location.pathname == "/register" ||
  //     location.pathname == "/user/personal" ||
  //     location.pathname == "/user/password" ||
  //     location.pathname == "/"
  //   )
  //     return;
  //   if (!user?.user?.email_is_confirmed) return;
  //   if (user?.user?.profile_completed_at) return;
  //   setLocationChangeCount((prevCount) => {
  //     const newCount = prevCount + 1;

  //     if (newCount % 3 === 0) {
  //       setCompleteProfileOpen(true);
  //     }
  //     return newCount;
  //   });
  // }, [location]);
  if (isLoading) return <Loader />;
  return (
    <ThemeCustomization>
      <AppContextProvider>
        <ScrollTop>
          <AuthProvider>
            <>
              <VerifyEmailModal open={verifyModalOpen} close={closeModal} />
              <ConsentCookies/>
              {/* <CompleteProfileModal
                open={completProfileOpen}
                close={closeCompleteProfile}
              /> */}
              <Notistack>
                <Routes />
                <Snackbar />
              </Notistack>
            </>
          </AuthProvider>
        </ScrollTop>
      </AppContextProvider>
    </ThemeCustomization>
  );
};

export default App;
