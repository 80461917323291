import {
  Box,
  TableCell,
  TableHead,
  TableSortLabel,
  TableRow,
  useMediaQuery,
} from "@mui/material";
import { visuallyHidden } from "@mui/utils";
const headCells = [
  {
    id: "id",
    numeric: false,
    disablePadding: true,
    label: "ID",
  },
  {
    id: "Date",
    numeric: false,
    disablePadding: false,
    label: "Date",
  },
  {
    id: "Service",
    numeric: false,
    disablePadding: false,
    label: "Service",
  },
  {
    id: "Exam",
    numeric: false,
    disablePadding: false,
    label: "Exam",
  },
  {
    id: "Exam Type",
    numeric: false,
    disablePadding: false,
    label: "Exam Type",
  },
  {
    id: "status",
    numeric: false,
    disablePadding: false,
    label: "Status",
  },
  {
    id: "operation",
    numeric: false,
    disablePadding: true,
    label: "operation",
  },
  //wcex
];
// project-imports
export default function ReportTableHead({ order, orderBy, onRequestSort }) {
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };
  const isMobile = useMediaQuery("(max-width:500px)");
  return (
    <TableHead
    >
      <TableRow
       
      >
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? "right" : "left"}
            padding={headCell.disablePadding ? "none" : "normal"}
            sortDirection={orderBy === headCell.id ? order : undefined}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === "desc" ? "sorted descending" : "sorted ascending"}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}
