import { Fragment, useState } from "react";

// material-ui
import {
  Box,
  Button,
  CircularProgress,
  FormControlLabel,
  Grid,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Radio,
  RadioGroup,
  Stack,
  Typography,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import SampleReport from "assets/images/sampleReport.png";
// assets
import { TickSquare } from "iconsax-react";

// project-imports
import useGetPlansList from "Api/hooks/useGetPlansList";
import useUser from "Api/hooks/useUser";
import MainCard from "components/MainCard";
import useAuth from "hooks/useAuth";
import { Link } from "react-router-dom";
import { getCurrencySymbol } from "utils/getCurrencySymbol";
import { toNumWithComma } from "utils/toNumWithComma";
import useGetCountry from "Api/hooks/useGetCountry";

// plan list

const planList = [
  "Overall band score",
  "Overall performance summary",
  "Components band score",
  "Key strength in each component",
  "Key issues in each component",
  "Suggestions for improvement in each component",
];

// ==============================|| PRICING ||============================== //

const Pricing = () => {
  const theme = useTheme();
  const { plans: allPlans, isLoading } = useGetPlansList();
  const { isLoggedIn } = useAuth();
  const { user, isPersian } = useUser();
  const {country} = useGetCountry()
  console.log(country)
  const [currencyRate, setCurrencyRate] = useState({
    title: "usd",
    code: "USD",
    rate: 1,
  });
  function createData(
    id,
    active,
    title,
    description,
    price,
    regular_price,
    price2
  ) {
    return {
      id,
      active,
      title,
      description,
      price,
      regular_price,
      price2,
    };
  }
  const plansList = allPlans?.map((plan) =>
    createData(
      plan?.id,
      plan?.is_active == 1,
      plan?.description,
      plan?.credit,
      plan?.price,
      plan?.regular_price,
      plan?.price2
    )
  );

  const priceSelectedPlan = {
    padding: 3,
    borderRadius: 1,
    border: `1px solid ${theme.palette.divider}`,
    bgcolor: theme.palette.primary.lighter,
  };

  const priceUnselectedPlan = {
    padding: 3,
    borderRadius: 1,
    border: `1px solid ${theme.palette.divider}`,
    bgcolor: theme.palette.background.paper,
  };
  function getDiscount(regular_price, price) {
    const discountAmount = regular_price - price;
    const discountPercent = (discountAmount / regular_price) * 100;
    return discountPercent;
  }
  const [price, setPrice] = useState("Standard");
  const [selectedPlan, setSelectedPlan] = useState();
  const handlePriceMethod = (value) => {
    setPrice(value);
  };
  console.log(plansList);
  if (isLoading) return <CircularProgress />;
  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <Stack
          spacing={2}
          direction={{ xs: "column", md: "row" }}
          justifyContent="space-between"
        >
          <Stack spacing={0}>
            <Typography variant="h5">
              Affordable Excellence: Experience high-quality support that’s easy
              on your wallet.
            </Typography>
          </Stack>
        </Stack>
      </Grid>
      <Grid item xs={12}>
        <Grid container spacing={3}>
          <Grid item xs={12} md={6} lg={7}>
            <MainCard>
              <Box sx={{ display: "block" }}>
                <List
                  sx={{
                    m: 0,
                    p: 0,
                    "&> li": {
                      px: 0,
                      py: 0.625,
                    },
                  }}
                  component="ul"
                >
                  {planList.map((list, i) => (
                    <Fragment key={i}>
                      <ListItem sx={{}} divider>
                        <ListItemIcon>
                          <TickSquare
                            size="16"
                            color={theme.palette.success.main}
                          />
                        </ListItemIcon>
                        <ListItemText primary={list} />
                      </ListItem>
                    </Fragment>
                  ))}
                </List>
              </Box>
            </MainCard>
            <MainCard sx={{ marginTop: "1rem" }}>
              <Stack direction="row" spacing={1}>
                <ListItemText
                  primary={
                    <ListItemText
                      primary="Sample Report"
                      secondary="A full report sample,
                             including all of our
                               provided features."
                    />
                  }
                  secondary={
                    <Button
                      component={Link}
                      to="/report/example"
                      target="_blank"
                    >
                      Sample Report
                    </Button>
                  }
                />
                <span className="report-preview">
                  <img
                    src={SampleReport}
                    style={{
                      width: "120px",
                      height: "120px",
                      borderRadius: "12px",
                    }}
                  />
                </span>
              </Stack>
            </MainCard>
          </Grid>
          <Grid item xs={12} md={6} lg={5}>
            <MainCard>
              <RadioGroup
                aria-label="delivery-options"
                value={price}
                onChange={(e) => handlePriceMethod(e.target.value)}
                name="Price-options"
              >
                <Stack spacing={2}>
                  {plansList?.map((plan, index) => (
                    <Box
                      key={index}
                      sx={
                        price === plan.title
                          ? priceSelectedPlan
                          : priceUnselectedPlan
                      }
                    >
                      <FormControlLabel
                        value={plan.title}
                        control={
                          <Radio onChange={() => setSelectedPlan(plan)} />
                        }
                        label={
                          <Stack
                            spacing={0.5}
                            direction="row"
                            alignItems="center"
                            justifyContent="space-between"
                            sx={{ width: "100%" }}
                          >
                            <Stack spacing={0}>
                              <Stack spacing={1} direction="row">
                                <Typography variant="h5">
                                  {plan.title}
                                </Typography>
                              </Stack>
                              <Typography>
                                {plan.description}&nbsp;
                                {plan?.description > 1 ? "writings" : "writing"}
                              </Typography>
                            </Stack>
                            <Stack
                              spacing={0}
                              alignItems="flex-end"
                              direction={{ sm: "column", xs: "column" }}
                            >
                              {plan.regular_price != plan.price && (
                                <Stack
                                  direction="row"
                                  alignItems="center"
                                  spacing={1}
                                >
                                  <span className="discount-badge">
                                    %
                                    {getDiscount(
                                      plan.regular_price,
                                      plan.price
                                    )}
                                  </span>
                                  <Typography
                                    sx={{ textDecoration: "line-through" }}
                                  >
                                    ${toNumWithComma(plan.regular_price)}
                                  </Typography>
                                </Stack>
                              )}
                              <Typography variant="h4">
                                {isPersian
                                  ? null
                                  : getCurrencySymbol(currencyRate.code)}
                                &nbsp;
                                {isPersian
                                  ? new Number(plan?.price2)
                                      .toString()
                                      .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                                  : toNumWithComma(
                                      plan.price * currencyRate.rate
                                    )}
                                {isPersian ? "T" : null}
                              </Typography>
                            </Stack>
                          </Stack>
                        }
                        sx={{
                          width: "100%",
                          alignItems: "flex-start",
                          "& .MuiSvgIcon-root": { fontSize: 32 },
                          "& .MuiFormControlLabel-label": { width: "100%" },
                          "& .MuiRadio-root": { p: 0, pl: 1, pr: 1, pt: 0.5 },
                        }}
                      />
                    </Box>
                  ))}
                </Stack>
              </RadioGroup>
              <Stack
                sx={{ marginTop: "1rem" }}
                justifyContent="center"
                direction="row"
              >
                {/* <div style={{width:'150px'}}>
                <DynamicCurrency setCurrencyRate={setCurrencyRate} />
                </div> */}
              </Stack>
            </MainCard>
            {selectedPlan && (
              <MainCard style={{ marginTop: "1rem" }}>
                <Stack
                  justifyContent="space-between"
                  direction={{ sm: "row", xs: "row" }}
                >
                  <Typography variant="h5">
                    {selectedPlan.title}
                    <Typography variant="h6" color="textSecondary">
                      {isPersian ? null : getCurrencySymbol(currencyRate.code)}
                      {isPersian
                        ? `${new Number(selectedPlan?.price2)
                            .toString()
                            .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}T`
                        : toNumWithComma(
                            selectedPlan?.price * currencyRate.rate
                          )}
                    </Typography>
                  </Typography>
                  <Button
                    sx={{ color: "black" }}
                    variant="contained"
                    LinkComponent={Link}
                    to={`/payment?planId=${selectedPlan?.id}`}
                  >
                    Buy
                  </Button>
                </Stack>
              </MainCard>
            )}
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default Pricing;
