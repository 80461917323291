import { useOutletContext } from "react-router";

// material-ui
import {
  Autocomplete,
  Box,
  Button,
  CardHeader,
  Checkbox,
  CircularProgress,
  Divider,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormHelperText,
  FormLabel,
  Grid,
  InputLabel,
  MenuItem,
  Modal,
  Radio,
  RadioGroup,
  Select,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import congrats from "../../../../assets/images/congrats.png";
import "react-phone-input-2/lib/material.css";
// third-party
import * as Yup from "yup";
import { Field, Formik } from "formik";

// project-imports
import MainCard from "components/MainCard";
import countries from "data/countries";
import { dispatch } from "store";
import { openSnackbar } from "store/reducers/snackbar";

// assets
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFnsV3";
import useUser from "Api/hooks/useUser";
import { useEffect, useRef, useState } from "react";
import FormikRadioGroup from "components/FormkiRadioGroup";
import useProfile from "Api/hooks/useProfile";
import { countryCodes } from "data/countryCodes";
import { convertDate } from "utils/convertDate";
import { parsePhoneNumber } from "utils/parsePhoneNumber";
import { Link } from "react-router-dom";
import PhoneInput from "react-phone-input-2";
import "../../../../components/countrySelect.css";
import useGetCountry from "Api/hooks/useGetCountry";
// styles & constant
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
    },
  },
};

function useInputRef() {
  return useOutletContext();
}

// ==============================|| USER PROFILE - PERSONAL ||============================== //
const examTypeOptions = [
  { label: "Academic", value: "academic" },
  { label: "General", value: "general" },
  { label: "Both", value: "both" },
];
const studyReasonOption = [
  { label: "To migrate", value: "to_migrate" },
  {
    label: "Study",
    value: "study",
  },
  {
    label: "Work",
    value: "work",
  },
];
const scoreGoal = [
  { value: 6.0 },
  { value: 7.0 },
  { value: 7.5 },
  { value: 8.0 },
  { value: 8.5 },
  { value: 9.0 },
];

const weaknesses = [
  { label: "Writing", value: "writing" },
  { label: "Reading", value: "reading" },
  { label: "Listening", value: "listening" },
  { label: "Speaking", value: "speaking" },
];

const discovery_methods = [
  { label: "Google", value: "google" },
  { label: "Telegram", value: "telegram" },
  { label: "Linkedin", value: "linkedin" },
  { label: "From a friend", value: "from_a_friend" },
  { label: "Instagram", value: "instagram" },
  { label: "Youtube", value: "youtube" },
  { label: "Online advertisment", value: "online_advertisment" },
  { label: "Other", value: "other" },
];
const TabPersonal = () => {
  const { profile, updateProfile, isUpdating, isProfileLoading } = useProfile();
  const {country:userCountry} = useGetCountry()
  console.log(userCountry)
  const [discoveryMethod, setDiscoveryMethod] = useState();
  useEffect(() => {
    if (
      !discovery_methods.some((item) => item.value == profile?.discovery_method)
    ) {
      setShowOtherInput(true);
      setDiscoveryMethod(profile?.discovery_method);
    } else {
      setShowOtherInput(false);
    }
  }, [profile]);
  const [showOtherInput, setShowOtherInput] = useState(false);
  const formRef = useRef(null);
  // const [isProfileCompleted, setIsProfileCompleted] = useState(false);
  // const [showModal, setShowModal] = useState(false);
  const handleChangeDay = (event, date, setFieldValue) => {
    setFieldValue(
      "dob",
      new Date(date.setDate(parseInt(event.target.value, 10)))
    );
  };

  // useEffect(() => {
  //   if(!user?.user?.profile_completed_at){
  //     localStorage.removeItem('profile-completed')
  //   }
  //   const isCompleted = localStorage.getItem("profile-completed");
  //   if (isCompleted) {
  //     setIsProfileCompleted(true);
  //   } else {
  //     setIsProfileCompleted(false);
  //   }
  // }, []);
  const handleChangeMonth = (event, date, setFieldValue, type = null) => {
    setFieldValue(
      type == "exam" ? "exam_date" : "dob",
      new Date(date.setMonth(parseInt(event.target.value, 10)))
    );
  };
  const handleChangeGender = (event, gender, setFieldValue) => {
    setFieldValue("gender", event.target.value);
  };
  function handleDiscoveryMethod() {
    setShowOtherInput(true);
  }
  function saveDiscoveryValue(setFieldValue) {
    setFieldValue("discovery_method", discoveryMethod);
  }
  function getFormattedDate(originalDate, type) {
    const date = new Date(originalDate);
    const day = date.getDate() || null;
    const month = date.getMonth() + 1;
    const year = date.getFullYear();
    const formattedDate = `${type == "exam" ? "" : day}${
      type == "exam" ? "" : "-"
    }${month}-${year}`;
    return formattedDate;
  }
  const maxDate = new Date();
  maxDate.setFullYear(maxDate.getFullYear());
  const inputRef = useInputRef();
  const { user, isLoading } = useUser();
  function handlePhoneNumber(setValue, value, country, e, formattedValue) {
    const number = value.slice(country.dialCode.length);
    setValue("countryCode", country.dialCode);
    setValue("contact", number);
  }
  console.log(countryCodes.find((code)=>code.name == userCountry).code);
  const parsedNumber = parsePhoneNumber(profile?.mobile);
  if (isLoading || !user.user?.name || isProfileLoading)
    return <CircularProgress sx={{ margin: "auto" }} />;
  return (
    <MainCard
      content={false}
      title="Personal Information"
      sx={{ "& .MuiInputLabel-root": { fontSize: "0.875rem" } }}
    >
      <Formik
        innerRef={formRef}
        enableReinitialize={true}
        initialValues={{
          fullname: user?.user?.name,
          email: user?.user?.email,
          dob: new Date(profile?.dob),
          gender: profile?.gender,
          country: profile?.country,
          submit: null,
          exam_type: profile?.exam_type,
          goal_score: Number(profile?.goal_score),
          study_reason: profile?.study_reason,
          weaknesses: profile?.weaknesses,
          countryCode: profile?.mobile ? `+${parsedNumber.code}` : countryCodes.find((code)=>code.name == userCountry).code,
          contact: parsedNumber.number,
          exam_date: convertDate(profile.exam_date),
          discovery_method: discovery_methods.some(
            (item) => item.value == profile?.discovery_method
          )
            ? profile?.discovery_method
            : "other",
        }}
        validationSchema={Yup.object().shape({
          fullname: Yup.string().max(255).required("Full Name is required."),
          email: Yup.string()
            .email("Invalid email address.")
            .max(255)
            .required("Email is required."),
          dob: Yup.date().required("Date of birth is requird."),
        })}
        onSubmit={(values, { setErrors, setStatus, setSubmitting }) => {
          const originalDob = values.dob;
          const originalExamDate = values.exam_date;
          const formattedDob = getFormattedDate(originalDob);
          const formattedExamDate = getFormattedDate(originalExamDate, "exam");
          const profileData = {
            dob: formattedDob,
            name: values.fullname,
            country: values.country,
            weaknesses: values.weaknesses,
            goal_score: values.goal_score,
            exam_type: values.exam_type,
            study_reason: values.study_reason,
            gender: values.gender,
            mobile: `${values.countryCode}-${values.contact}`,
            exam_date: formattedExamDate,
            discovery_method: values.discovery_method,
          };

          try {
            updateProfile(profileData, {
              onSuccess: () => {
                dispatch(
                  openSnackbar({
                    open: true,
                    message: "Personal profile updated successfully.",
                    variant: "alert",
                    alert: {
                      color: "success",
                    },
                    close: false,
                  })
                );
                setStatus({ success: false });
                setSubmitting(false);
                // if (!isProfileCompleted) {
                //   setShowModal(true);
                //   localStorage.setItem("profile-completed", true);
                // }
              },
            });
          } catch (err) {
            setStatus({ success: false });
            setErrors({ submit: err.message });
            setSubmitting(false);
          }
        }}
      >
        {({
          errors,
          handleBlur,
          handleChange,
          handleSubmit,
          isSubmitting,
          setFieldValue,
          touched,
          values,
        }) => {
          return (
            <form noValidate onSubmit={handleSubmit}>
              <Box sx={{ p: 2.5 }}>
                <Grid container spacing={3}>
                  <Grid item xs={12} sm={6}>
                    <Stack spacing={1.25}>
                      <InputLabel htmlFor="personal-full-name">
                        Full Name
                      </InputLabel>
                      <TextField
                        fullWidth
                        id="personal-full-name"
                        value={values.fullname}
                        name="fullname"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        placeholder="Full name"
                        autoFocus
                        inputRef={inputRef}
                      />
                      {touched.firstname && errors.firstname && (
                        <FormHelperText error id="personal-first-name-helper">
                          {errors.firstname}
                        </FormHelperText>
                      )}
                    </Stack>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Stack spacing={1.25}>
                      <InputLabel htmlFor="personal-email">
                        Email Address
                      </InputLabel>
                      <TextField
                        disabled
                        type="email"
                        fullWidth
                        value={values.email}
                        name="email"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        id="personal-email"
                        placeholder="Email Address"
                      />
                      {touched.email && errors.email && (
                        <FormHelperText error id="personal-email-helper">
                          {errors.email}
                        </FormHelperText>
                      )}
                    </Stack>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Stack spacing={1.25}>
                      <InputLabel htmlFor="personal-date">
                        Date of Birth
                      </InputLabel>
                      <Stack
                        direction="row"
                        justifyContent="space-between"
                        alignItems="center"
                        spacing={0.5}
                      >
                        <Select
                          fullWidth
                          value={values.dob.getMonth().toString()}
                          name="dob-month"
                          onChange={(e) =>
                            handleChangeMonth(e, values.dob, setFieldValue)
                          }
                        >
                          <MenuItem value="0">January</MenuItem>
                          <MenuItem value="1">February</MenuItem>
                          <MenuItem value="2">March</MenuItem>
                          <MenuItem value="3">April</MenuItem>
                          <MenuItem value="4">May</MenuItem>
                          <MenuItem value="5">June</MenuItem>
                          <MenuItem value="6">July</MenuItem>
                          <MenuItem value="7">August</MenuItem>
                          <MenuItem value="8">September</MenuItem>
                          <MenuItem value="9">October</MenuItem>
                          <MenuItem value="10">November</MenuItem>
                          <MenuItem value="11">December</MenuItem>
                        </Select>

                        <Select
                          fullWidth
                          value={values.dob.getDate().toString()}
                          name="dob-date"
                          onBlur={handleBlur}
                          onChange={(e) =>
                            handleChangeDay(e, values.dob, setFieldValue)
                          }
                          MenuProps={MenuProps}
                        >
                          {[
                            1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15,
                            16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28,
                            29, 30, 31,
                          ].map((i) => (
                            <MenuItem
                              key={i}
                              value={i}
                              disabled={
                                (values.dob.getMonth() === 1 &&
                                  i >
                                    (values.dob.getFullYear() % 4 === 0
                                      ? 29
                                      : 28)) ||
                                (values.dob.getMonth() % 2 !== 0 &&
                                  values.dob.getMonth() < 7 &&
                                  i > 30) ||
                                (values.dob.getMonth() % 2 === 0 &&
                                  values.dob.getMonth() > 7 &&
                                  i > 30)
                              }
                            >
                              {i}
                            </MenuItem>
                          ))}
                        </Select>

                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                          <DatePicker
                            views={["year"]}
                            value={values.dob}
                            maxDate={maxDate}
                            onChange={(newValue) => {
                              console.log(newValue);
                              setFieldValue("dob", newValue);
                            }}
                            sx={{ width: 1 }}
                          />
                        </LocalizationProvider>
                      </Stack>
                      {touched.dob && errors.dob && (
                        <FormHelperText error id="personal-dob-helper">
                          {errors.dob}
                        </FormHelperText>
                      )}
                    </Stack>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Stack spacing={1.25}>
                      <InputLabel htmlFor="personal-gender">Gender</InputLabel>
                      <Stack
                        direction="row"
                        justifyContent="space-between"
                        alignItems="center"
                        spacing={2}
                      >
                        <Select
                          fullWidth
                          value={values.gender}
                          name="gender"
                          onChange={(e) =>
                            handleChangeGender(e, values.gender, setFieldValue)
                          }
                        >
                          <MenuItem value="MALE">Male</MenuItem>
                          <MenuItem value="FEMALE">Female</MenuItem>
                          <MenuItem value="OTHER">Other</MenuItem>
                        </Select>
                      </Stack>
                      {touched.gender && errors.gender && (
                        <FormHelperText error id="personal-gender-helper">
                          {errors.gender}
                        </FormHelperText>
                      )}
                    </Stack>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Stack spacing={1.25}>
                      <InputLabel>Phone number</InputLabel>
                      <PhoneInput
                        id="phone"
                        containerClass="phone-number"
                        inputClass="phone-number-input"
                        country="ir"
                        value={`${values.countryCode}${values.contact}`}
                        onChange={(value, country, e, formattedValue) =>
                          handlePhoneNumber(
                            setFieldValue,
                            value,
                            country,
                            e,
                            formattedValue
                          )
                        }
                      />
                    </Stack>
                  </Grid>
                </Grid>
              </Box>
              <CardHeader title="Address" />
              <Divider />
              <Box sx={{ p: 2.5 }}>
                <Grid container spacing={3}>
                  <Grid item xs={12} sm={6}>
                    <Stack spacing={1.25}>
                      <InputLabel htmlFor="personal-country">
                        Country
                      </InputLabel>
                      <Autocomplete
                        id="personal-country"
                        fullWidth
                        value={
                          countries.filter(
                            (item) => item.code === values?.country
                          )[0]
                        }
                        onBlur={handleBlur}
                        onChange={(event, newValue) => {
                          setFieldValue(
                            "country",
                            newValue === null ? "" : newValue.code
                          );
                        }}
                        options={countries}
                        autoHighlight
                        isOptionEqualToValue={(option, value) =>
                          option.code === value?.code
                        }
                        getOptionLabel={(option) => option.label}
                        renderOption={(props, option) => (
                          <Box
                            component="li"
                            sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
                            {...props}
                          >
                            {option.code && (
                              <img
                                loading="lazy"
                                width="20"
                                src={`https://flagcdn.com/w20/${option.code.toLowerCase()}.png`}
                                srcSet={`https://flagcdn.com/w40/${option.code.toLowerCase()}.png 2x`}
                                alt=""
                              />
                            )}
                            {option.label}
                            {option.code && `(${option.code}) ${option.phone}`}
                          </Box>
                        )}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            size="medium"
                            placeholder="Choose a country"
                            name="country"
                            inputProps={{
                              ...params.inputProps,
                              autoComplete: "new-password",
                            }}
                          />
                        )}
                      />
                      {touched.country && errors.country && (
                        <FormHelperText error id="personal-country-helper">
                          {errors.country}
                        </FormHelperText>
                      )}
                    </Stack>
                  </Grid>
                </Grid>
              </Box>
              <CardHeader title="Professional information" />
              <Divider />
              <Box sx={{ p: 2.5 }}>
                <Grid item xs={12}>
                  <Grid container spacing={3}>
                    <Grid item xs={6}>
                      <FormControl
                        component="fieldset"
                        onChange={handleChange}
                        name="exam_type"
                      >
                        <FormLabel>IELTS Exam type</FormLabel>
                        <RadioGroup
                          aria-label="size"
                          defaultValue={values.exam_type}
                          name="exam_type"
                        >
                          {examTypeOptions.map((item) => (
                            <FormControlLabel
                              value={values.exam_type}
                              control={
                                <Radio name="exam_type" value={item.value} />
                              }
                              label={item.label}
                            />
                          ))}
                        </RadioGroup>
                      </FormControl>
                    </Grid>
                    <Grid item xs={6}>
                      <FormControl component="fieldset" onChange={handleChange}>
                        <FormLabel>Purpose of taking IELTS</FormLabel>
                        <RadioGroup
                          aria-label="size"
                          defaultValue={values?.study_reason}
                          name="study_reason"
                        >
                          {studyReasonOption.map((item) => (
                            <FormControlLabel
                              name="study_reason"
                              value={values.study_reason}
                              control={
                                <Radio name="study_reason" value={item.value} />
                              }
                              label={item.label}
                            />
                          ))}
                        </RadioGroup>
                      </FormControl>
                    </Grid>
                    <Grid item xs={6}>
                      <FormControl component="fieldset" onChange={handleChange}>
                        <FormLabel>Target Score</FormLabel>
                        <RadioGroup
                          aria-label="size"
                          name="goal_score"
                          value={values?.goal_score}
                          defaultValue={parseInt(values?.goal_score)}
                        >
                          {scoreGoal.map((item) => (
                            <FormControlLabel
                              name="goal_score"
                              value={values.scoreGoal}
                              control={
                                <Radio name="goal_score" value={item.value} />
                              }
                              label={item.value}
                            />
                          ))}
                        </RadioGroup>
                      </FormControl>
                    </Grid>
                    <Grid item xs={6}>
                      <FormControl
                        component="fieldset"
                        value={values.weaknesses}
                        onChange={handleChange}
                      >
                        <FormLabel>Weaknesses</FormLabel>
                        <FormGroup
                          onChange={handleChange}
                          value={values.weaknesses}
                          name="weaknesses"
                        >
                          {weaknesses.map((item) => (
                            <FormControlLabel
                              name="weaknesses"
                              control={
                                <Checkbox
                                  name="weaknesses"
                                  checked={values?.weaknesses?.includes(
                                    item.value
                                  )}
                                  value={item.value}
                                />
                              }
                              label={item.label}
                            />
                          ))}
                        </FormGroup>
                      </FormControl>
                    </Grid>
                    <Grid item xs={12}>
                      <Stack spacing={1.25}>
                        <InputLabel htmlFor="personal-date">
                          Planned IELTS exam date
                        </InputLabel>
                        <Stack
                          direction="row"
                          justifyContent="space-between"
                          alignItems="center"
                          spacing={0.5}
                        >
                          <Select
                            fullWidth
                            value={values.dob.getMonth().toString()}
                            name="exam-date-month"
                            onChange={(e) =>
                              handleChangeMonth(
                                e,
                                values.dob,
                                setFieldValue,
                                "exam"
                              )
                            }
                          >
                            <MenuItem value="0">January</MenuItem>
                            <MenuItem value="1">February</MenuItem>
                            <MenuItem value="2">March</MenuItem>
                            <MenuItem value="3">April</MenuItem>
                            <MenuItem value="4">May</MenuItem>
                            <MenuItem value="5">June</MenuItem>
                            <MenuItem value="6">July</MenuItem>
                            <MenuItem value="7">August</MenuItem>
                            <MenuItem value="8">September</MenuItem>
                            <MenuItem value="9">October</MenuItem>
                            <MenuItem value="10">November</MenuItem>
                            <MenuItem value="11">December</MenuItem>
                          </Select>

                          <LocalizationProvider dateAdapter={AdapterDateFns}>
                            <DatePicker
                              views={["year"]}
                              value={values.exam_date}
                              minDate={new Date()}
                              onChange={(newValue) => {
                                console.log(newValue);
                                setFieldValue("exam_date", newValue);
                              }}
                              sx={{ width: 1 }}
                            />
                          </LocalizationProvider>
                        </Stack>
                        {touched.dob && errors.dob && (
                          <FormHelperText error id="personal-dob-helper">
                            {errors.dob}
                          </FormHelperText>
                        )}
                      </Stack>
                    </Grid>
                  </Grid>
                </Grid>
              </Box>
              <CardHeader title="Other" />
              <Divider />
              <Box sx={{ p: 2.5 }}>
                <Stack>
                  <FormControl
                    component="fieldset"
                    onChange={handleChange}
                    name="discovery_method"
                  >
                    <FormLabel>How did you hear from us?</FormLabel>
                    <RadioGroup
                      aria-label="size"
                      defaultValue={values.discovery_method}
                      name="discovery_method"
                    >
                      <Stack
                        gap={1}
                        sx={{ mt: "1rem", padding: "1rem" }}
                        direction="row"
                        flexWrap="wrap"
                      >
                        {discovery_methods.map((item) => {
                          if (item.value == "other") {
                            return (
                              <FormControlLabel
                                sx={{
                                  width: "200px",
                                  borderRadius: "12px",
                                  bgcolor: "#f5f5f4",
                                }}
                                value={values.discovery_method}
                                control={
                                  <Radio
                                    onClick={handleDiscoveryMethod}
                                    name="discovery_method"
                                    value={item.value}
                                  />
                                }
                                label={item.label}
                              />
                            );
                          }
                          return (
                            <FormControlLabel
                              sx={{
                                width: "200px",
                                borderRadius: "12px",
                                bgcolor: "#f5f5f4",
                              }}
                              value={values.discovery_method}
                              control={
                                <Radio
                                  name="discovery_method"
                                  value={item.value}
                                  onClick={() => setShowOtherInput(false)}
                                />
                              }
                              label={item.label}
                            />
                          );
                        })}
                        {showOtherInput && (
                          <>
                            <TextField
                              placeholder="please specify"
                              value={discoveryMethod}
                              onBlur={() => saveDiscoveryValue(setFieldValue)}
                              onChange={(e) =>
                                setDiscoveryMethod(e.target.value)
                              }
                            />
                          </>
                        )}
                      </Stack>
                    </RadioGroup>
                  </FormControl>
                </Stack>
              </Box>
              <Box sx={{ p: 2.5 }}>
                <Stack
                  direction="row"
                  justifyContent="flex-end"
                  alignItems="center"
                  spacing={2}
                  sx={{ mt: 2.5 }}
                >
                  <Button variant="outlined" color="secondary">
                    Cancel
                  </Button>
                  <Button
                    disabled={isUpdating || Object.keys(errors).length !== 0}
                    type="submit"
                    variant="contained"
                  >
                    Save
                  </Button>
                </Stack>
              </Box>
            </form>
          );
        }}
      </Formik>
      {/* {showModal && (
        <div>
          <Modal
            open={showModal}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            sx={{
              "& .MuiModal-backdrop": {
                backgroundColor: "rgba(0, 0, 0, 0.5);",
              },
            }}
          >
            <Stack
              alignItems="center"
              justifyContent="center"
              sx={{ height: "100svh" }}
            >
              <MainCard
                sx={{ minWidth: "300px", maxWidth: "450px", margin: "0 1rem" }}
              >
                <Stack alignItems="center" gap="1rem">
                  <img src={congrats} width={200} height={200} />
                  <Typography variant="h3">Congrats!</Typography>
                  <Typography variant="subtitle1">
                    Your profile has been completed and you have been granted a
                    free writing credit that you can use in our platform!
                  </Typography>
                  <Button variant="contained" LinkComponent={Link} to="/">
                    Continue
                  </Button>
                </Stack>
              </MainCard>
            </Stack>
          </Modal>
        </div>
      )} */}
    </MainCard>
  );
};

export default TabPersonal;
