import { useQuery } from "@tanstack/react-query";
import { editedExamApi } from "Api/providers/writingProviders";

export default function useGetEditedExam(examId){
    const {data:editedExam,isLoading:isEditedExamLoading,refetch} = useQuery({
        queryKey:['edited-exam',examId],
        queryFn:()=>editedExamApi(examId),
        enabled:!!examId,
        refetchOnWindowFocus:false
    })
    return {editedExam,isEditedExamLoading,refetch}
}