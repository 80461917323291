// material-ui
import { Alert, MenuItem, Select, Stack, Typography } from "@mui/material";

// project-imports
import MainCard from "components/MainCard";
import { InfoCircle } from "iconsax-react";
import ApexLineChart from "sections/charts/apexchart/ApexLineChart";

// assets

// ==============================|| CHART - REPEAT CUSTOMER RATE ||============================== //

const OverallScoreChart = ({
  scores,
  taskType,
  setTaskType,
  examType,
  setExamType,
}) => {
  return (
    <MainCard>
      <Stack
        direction={{ xs: "column", sm: "row" }}
        alignItems="center"
        justifyContent="space-between"
        spacing={1}
        sx={{ mb: "1rem" }}
      >
        <Typography variant="h5">Exam scores</Typography>
        <Stack direction="row" gap="1rem">
          <Select
            value={taskType}
            onChange={(e) => setTaskType(e.target.value)}
          >
            <MenuItem value="1">Task 1</MenuItem>
            <MenuItem value="2">Task 2</MenuItem>
          </Select>
          <Select
            value={examType}
            onChange={(e) => setExamType(e.target.value)}
          >
            <MenuItem value="academic">IELTS Academic</MenuItem>
            <MenuItem value="general">IELTS General</MenuItem>
          </Select>
        </Stack>
      </Stack>
      <ApexLineChart scores={scores} />
      <Stack sx={{ width: "100%" }} justifyContent="center" alignItems="center">
        <Alert variant="" icon={<InfoCircle />} sx={{opacity:'50%'}}>
  
          <Typography variant="caption">

            You can toggle each component on and off by simply clicking on the
            colored bullet points!
          </Typography>
        </Alert>
      </Stack>
    </MainCard>
  );
};

export default OverallScoreChart;
