import { Alert, Stack, Typography } from '@mui/material'
import useUser from 'Api/hooks/useUser'
import { InfoCircle } from 'iconsax-react'
import React from 'react'

function Unsubscribe() {
  const {user} = useUser()
  return (
    <Stack width="100%" height="70svh" alignItems="center" justifyContent="center">
      <div style={{maxWidth:'500px'}}>
      <Alert icon={<InfoCircle/>}>
        <Typography fontWeight={400} fontSize={24}>
        We're sorry to see you go, but we respect your decision. You have successfully unsubscribed from our email list and will no longer receive updates from us.
        </Typography>
      </Alert>
      </div>
    </Stack>
  )
}

export default Unsubscribe