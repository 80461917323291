export function convertDate(dateStr) {
  if (!dateStr) return new Date();
  // Split the input string by the hyphen
  const [month, year] = dateStr.split("-");

  // Create a new Date object
  // Note: JavaScript Date object months are zero-indexed (0 = January, 11 = December)
  const date = new Date(year, month - 1);

  return date;
}
